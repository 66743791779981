import React from 'react'
// import { ArrowUpward, ArrowDownward } from '@material-ui/icons'

interface TableHeaderProps {
  title?: string
  className?: string
  orderDown?: boolean | null
  onClick?: () => void
}

export const TableHeader: React.FunctionComponent<TableHeaderProps> = props => {
  const { title, onClick, className } = props
  //const [orderDown, setOrderDown] = useState<boolean | null>(null)

  // const ordering = () => {
  //   if (orderDown === null ) return <div />
  //   else if (orderDown === true) return <ArrowUpward style={{ fontSize: 16 }} />  
  //   else return <ArrowDownward style={{ fontSize: 16 }} />
  // }
   
    
return (
  <div className={`${className} TableHeader`} onClick={onClick}>
    <span className="subhead04">{title}</span>
    {/* <div className="TableHeader--order">
      {ordering()}
    </div> */}
  </div>
)
}