import { gql } from 'apollo-boost'
import { FilterDataTypes } from '../utils/filter'
import {
    dateFormatter,
    defaultFormatter, ITableDataShape,
    ITableDataShapeMeta,
    joinTinFormatter,
    missingFormatter,
    rightAlignFormatter
} from '../utils/formatter'

export const QUERY_CMT = gql`
    query getCmtTransactions($ignoreZeros: Boolean, $limit: Int, $offset: Int, $filters: [Filter], $sorting: Sorting) {
        cmt_transactions(ignoreZeros: $ignoreZeros, limit: $limit, offset: $offset, filters: $filters, sorting: $sorting) {
            id
            cp_code
            cp_name
            investor_type
            description
            tax_number
            tax_number_2
            currency_code
            financial_year
            financial_month
            movement_date
            ail
            rwt
            nrwt
            interest
            other
            withhold_code
            withhold_method
        }
    }
`

export interface ICmtTransaction {
    id: string
    cp_code: string
    cp_name?: string
    investor_type?: string
    description?: string
    tax_number?: string
    tax_number_2?: string
    currency_code?: string
    financial_year: number
    financial_month: number
    movement_date?: string
    ail?: string
    rwt?: string
    nrwt?: string
    interest?: string
    other?: string
    withhold_code?: string
    withhold_method?: string
}

export interface IGetCmtTransaction {
    cmt_transactions: ICmtTransaction[]
}

const headerData: ICmtTransaction = {
    id: '',
    cp_code: '',
    financial_year: 0,
    financial_month: 0,
    investor_type: 'INDIVIDUAL'
};

const convert = (cmtTransactions: ICmtTransaction[]): ITableDataShape[][] => {
    return cmtTransactions.map(transaction => [
        {
            label: 'CP Code',
            dataType: FilterDataTypes.string,
            content: transaction.cp_code,
            value: 'cp_code',
            width: 120,
            formatter: defaultFormatter
        },
        {
            label: 'Name',
            dataType: FilterDataTypes.string,
            content: transaction.cp_name,
            value: 'cp_name',
            width: 460,
            formatter: defaultFormatter
        },
        {
            label: 'Investor Type',
            dataType: FilterDataTypes.enum,
            content: transaction.investor_type,
            value: 'investor_type',
            formatter: defaultFormatter
        },
        {
            label: 'TIN',
            dataType: FilterDataTypes.string,
            content: transaction.tax_number,
            value: 'tax_number',
            filterOnly: transaction.investor_type === 'JOINT_INDIVIDUAL',
            width: 260,
            formatter: missingFormatter,
        },
        {
            label: 'TIN 2',
            dataType: FilterDataTypes.string,
            content: transaction.tax_number + '&' + transaction.tax_number_2,
            value: 'tax_number_2',
            filterOnly: transaction.investor_type !== 'JOINT_INDIVIDUAL',
            width: 260,
            formatter: joinTinFormatter,
        },
        {
            label: 'Curr.',
            dataType: FilterDataTypes.string,
            content: transaction.currency_code,
            value: 'currency_code',
            width: 80,
            formatter: defaultFormatter
        },
        {
            label: 'Fin. Year',
            dataType: FilterDataTypes.number,
            content: transaction.financial_year,
            value: 'financial_year',
            width: 80,
            formatter: defaultFormatter
        },
        {
            label: 'Fin. Month',
            dataType: FilterDataTypes.number,
            content: transaction.financial_month,
            value: 'financial_month',
            width: 80,
            formatter: defaultFormatter
        },
        {
            label: 'AIL',
            dataType: FilterDataTypes.number,
            content: transaction.ail,
            value: 'ail',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'RWT',
            dataType: FilterDataTypes.number,
            content: transaction.rwt,
            value: 'rwt',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'NRWT',
            dataType: FilterDataTypes.number,
            content: transaction.nrwt,
            value: 'nrwt',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'Gross Inc.',
            dataType: FilterDataTypes.number,
            content: transaction.interest,
            value: 'interest',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'Other',
            dataType: FilterDataTypes.number,
            content: transaction.other,
            value: 'other',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'WT Code',
            dataType: FilterDataTypes.string,
            content: transaction.withhold_code,
            value: 'withhold_code',
            formatter: defaultFormatter
        },
        {
            label: 'WT Method',
            dataType: FilterDataTypes.string,
            content: transaction.withhold_method,
            value: 'withhold_method',
            formatter: defaultFormatter
        },
        {
            label: 'MOV. Date',
            dataType: FilterDataTypes.date,
            content: transaction.movement_date,
            value: 'movement_date',
            formatter: dateFormatter //  todo improve date formatter
        }
    ])
}


export const cmtTransactionFilterFields = (): ITableDataShape[] => convert([headerData])[0]

export const cmtTransactionTableDataMaker = (cmtTransactions: ICmtTransaction[]): ITableDataShape[][] => convert(cmtTransactions)


