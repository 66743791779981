import { fetchConfig } from '../utils/config'
import { getAuthService } from '../utils/auth0.service'

export const formatDate = (date: string | number | Date | null | undefined): string => {
    if (!date) return ''
    const dt = new Date((date + '').replace(/(\d{2})\/(\d{2})\/(\d{2})/,'20$3-$2-$1'));
    return new Date(dt).toLocaleDateString('en-NZ', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    })
}

export const downloadCSV = async (filename: string, data: object) => {
    const config = await fetchConfig
    const token = getAuthService(config).getAccessToken()
    const response = await fetch(`${config.api_url}/export/${filename}`, {
        method: 'POST',
        // mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'text/csv',
            authorization: token ? `Bearer ${token}` : ''
        }
    })
    const blob = await response.blob()
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = filename
    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
    a.remove()
}

export const uploadCSV = async (file: File) => {
    const config = await fetchConfig
    const token = getAuthService(config).getAccessToken()
    const formData = new FormData();
    formData.append("file", file);

    return fetch(`${config.api_url}/upload`, {
        method: 'POST',
        body: formData,
        headers: { authorization: token ? `Bearer ${token}` : '' }
    })
}

export const createReport = async (year: number, month: number) => {
    const config = await fetchConfig
    const token = getAuthService(config).getAccessToken()

    return fetch(`${config.api_url}/create-report`, {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({ year: year, month: month }),
        headers: {
            'Content-Type': 'application/json',
            authorization: token ? `Bearer ${token}` : ''
        }
    })
}

export interface ICounter {
    name: string,
    count: number
}

export const getCountForName = (counters: ICounter[], name: string) => {
    const count = counters.find(c => c.name === name)
    return !!count ? count.count : 0
}
