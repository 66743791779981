import * as React from 'react'
import { FilterDataTypes } from './filter'
import { formatDate } from './common'

export enum ITableDataShapeMeta {
    rightAlign = 'rightAlign',
    isOrange = 'isOrange',
    isYellow = 'isYellow',
    isGreen = 'isGreen',
    isTeal = 'isTeal',
    isOdd = 'isOdd',
    isEven = 'isEven'
}

export interface ITableDataShape {
    label: string
    dataType: FilterDataTypes
    content?: string | number | Date | null | undefined
    value: string // attribute
    formatter: (value: string | number | Date | null | undefined) => React.ReactNode
    meta?: ITableDataShapeMeta[]
    width?: number
    filterOnly?: boolean
}

const formatMissing = (data: null | string | Date | number | undefined) => {
    if (data === null || data === '' || data === 'null' || data === 'undefined') {
        return <span className="isAlert">missing</span>
    } else {
        return data
    }
}

export const rightAlignFormatter = (value: string | number | Date | null | undefined) => <p className='isAlignRight'>{value}</p>
export const defaultFormatter = (value: string | number | Date | null | undefined) => <p> {value} </p>
export const dateFormatter = (value: string | number | Date | null | undefined) => <p> {formatDate(value)} </p>
export const missingFormatter = (value: string | number | Date | null | undefined) => <p> {formatMissing(value)} </p>
export const joinTinFormatter = (value: string | number | Date | null | undefined) => {
    const [tax1, tax2] = (value + '').split('&');
    return <p>
        {formatMissing(tax1)}
        <span className='label'> & </span>
        {formatMissing(tax2)}
    </p>
}

