export interface IConfig {
    api_url: string,
    token: string,
    auth0ClientID: string,
    auth0Domain: string,
    auth0Audience: string,
    skipAuth0: boolean,
    isLocalDev: boolean
}

// export const config: IConfig = (window as any).env;

export const fetchConfig: Promise<IConfig> =
    fetch('config.json')
        .then((response: Response) => response.json())
