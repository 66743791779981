import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowBack } from '@material-ui/icons'
import { Button } from '@jarden-digital/component-lib'

interface ScreenTitleProps {
    title?: string
    count?: number
    icon?: JSX.Element
    arrowNaviagteTo?: string
    reportingPeriod?: JSX.Element
    selectView?: JSX.Element
    // exportLinkHref?: string
    onExportClick?: () => void
}



export const ScreenTitle: React.FunctionComponent<ScreenTitleProps> = props => {
    const { title, count, icon, arrowNaviagteTo, onExportClick, reportingPeriod, selectView } = props

    // const link = !!exportLinkHref ? (
    //     <a className='fakeButton' target="blank" href={exportLinkHref}>Export</a>
    // ) : <div />
    const button = !!onExportClick ? (
        <Button secondary={true} onClick={onExportClick}>Export</Button>
    ): <div />
    const back = !!arrowNaviagteTo ? (
        <Link to={arrowNaviagteTo}
            className="gridActions goBack"><ArrowBack />
        </Link>
    ) : <div />

    return (
        <div className="ScreenTitle grid2Columns ">
            {back}
            <div className='gridContents grid2ColumnsContents col1'>
                {icon}
                <h1 className="header01">{title}</h1>
                <p>{count}</p>
            </div>
            <div className='grid2ColumnsContents col2'>
                {!!reportingPeriod ? reportingPeriod : null}
                {!!selectView ? selectView : null}
                {button}
            </div>
        </div >
    )
}
