import React from 'react'

interface SubHeaderProps{
  title?: string
}

export const SubHeader: React.FunctionComponent<SubHeaderProps>  = props => {
  const { title } = props
    return (
        <div className="SubHeader">
            <div/>
            <h3 className="subhead01">{title}</h3>
        </div>
    )
}