import { gql } from 'apollo-boost'
import {
    dateFormatter,
    defaultFormatter,
    ITableDataShape,
    ITableDataShapeMeta,
    rightAlignFormatter
} from '../utils/formatter'
import { FilterDataTypes } from '../utils/filter'

export const QUERY_DIVIDENDS = gql`
    query getDividends($limit: Int, $offset: Int, $filters: [Filter], $sorting: Sorting) {
        dividends(limit: $limit, offset: $offset, filters: $filters, sorting: $sorting) {
            id
            cp_code
            account_id
            financial_year
            financial_month
            currency_code
            movement_date
            description
            trade_id
            debit_amount
            credit_amount
            net_amount
            imputation_credits
            withholding_tax
            gross_adjustment_value
            interest_rate
            movement_type
            movement_pk
            origin_reference
            journal_type
	        withhold_code
        }
    }
`

export interface IDividend {
    id: string
	cp_code: string
	account_id: string
	financial_year: number
	financial_month: number
	currency_code?: string
	movement_date?: string
	description?: string
	trade_id?: string
	debit_amount?: string
	credit_amount?: string
	net_amount?: string
    imputation_credits?: string
    withholding_tax?: string
    gross_adjustment_value?: string
	interest_rate?: string
	movement_type?: string
    movement_pk?: string
	origin_reference?: string
	journal_type?: string
	withhold_code?: string
}

export interface IGetDividends {
    dividends: IDividend[]
}

const headerData: IDividend = {
    id: '',
    account_id: '',
    cp_code: '',
    financial_month: 0,
    financial_year: 0
}

const convert = (dividends: IDividend[]): ITableDataShape[][] => {
    return dividends.map(dividend => [
        {
            label: 'CP Code',
            dataType: FilterDataTypes.string,
            content: dividend.cp_code,
            value: 'cp_code',
            width: 120,
            formatter: defaultFormatter
        },
        {
            label: 'Acc. ID',
            dataType: FilterDataTypes.string,
            content: dividend.account_id,
            value: 'account_id',
            width: 120,
            formatter: defaultFormatter
        },
        {
            label: 'Fin. Year',
            dataType: FilterDataTypes.number,
            content: dividend.financial_year,
            value: 'financial_year',
            width: 80,
            formatter: defaultFormatter
        },
        {
            label: 'Fin. Month',
            dataType: FilterDataTypes.number,
            content: dividend.financial_month,
            value: 'financial_month',
            width: 80,
            formatter: defaultFormatter
        },
        {
            label: 'Description',
            dataType: FilterDataTypes.string,
            content: dividend.description,
            value: 'description',
            width: 560,
            formatter: defaultFormatter
        },
        {
            label: 'Curr.',
            dataType: FilterDataTypes.string,
            content: dividend.currency_code,
            value: 'currency_code',
            formatter: defaultFormatter
        },
        {
            label: 'Trade ID',
            dataType: FilterDataTypes.string,
            content: dividend.trade_id,
            value: 'trade_id',
            formatter: defaultFormatter
        },
        {
            label: 'Debit',
            dataType: FilterDataTypes.number,
            content: dividend.debit_amount,
            value: 'debit_amount',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'Credit',
            dataType: FilterDataTypes.number,
            content: dividend.credit_amount,
            value: 'credit_amount',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'Net',
            dataType: FilterDataTypes.number,
            content: dividend.net_amount,
            value: 'net_amount',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'IC.',
            dataType: FilterDataTypes.number,
            content: dividend.imputation_credits,
            value: 'imputation_credits',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'WHT',
            dataType: FilterDataTypes.number,
            content: dividend.withholding_tax,
            value: 'withholding_tax',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'Gross DIV.',
            dataType: FilterDataTypes.number,
            content: dividend.gross_adjustment_value,
            value: 'gross_adjustment_value',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'Int. Rate',
            dataType: FilterDataTypes.number,
            content: dividend.interest_rate,
            value: 'interest_rate',
            meta: [ITableDataShapeMeta.rightAlign],
            formatter: rightAlignFormatter
        },
        {
            label: 'MOV. PK',
            dataType: FilterDataTypes.string,
            content: dividend.movement_pk,
            value: 'movement_pk',
            formatter: defaultFormatter
        },
        {
            label: 'MOV. Type',
            dataType: FilterDataTypes.string,
            content: dividend.movement_type,
            value: 'movement_type',
            width: 220,
            formatter: defaultFormatter
        },
        {
            label: 'Origin',
            dataType: FilterDataTypes.string,
            content: dividend.origin_reference,
            value: 'origin_reference',
            formatter: defaultFormatter
        },
        {
            label: 'Journal Type',
            dataType: FilterDataTypes.string,
            content: dividend.journal_type,
            value: 'journal_type',
            width: 320,
            formatter: defaultFormatter
        },
        {
            label: 'MOV. Date', // Table header, filter field
            dataType: FilterDataTypes.date, // filter
            content: dividend.movement_date, // display data in table
            value: 'movement_date', // attribute to search in filter
            width: 160, // display for table
            formatter: dateFormatter // display for table
        },
    ])
}

export const dividendsFilterFields = (): ITableDataShape[] => convert([headerData])[0]

export const dividendsTableDataMaker = (dividends: IDividend[]): ITableDataShape[][] => convert(dividends)
