import * as React from 'react'
import { SubHeader } from './SubHeader'
import { downloadCSV } from '../../utils/common'
import { IAuditPeriod } from '../../api/dashboard'


const exportAdjustments = (params: IAuditPeriod) => {
    downloadCSV('adjustments.csv', params)
}

const exportStaticChanges = (params: IAuditPeriod) => {
    downloadCSV('static_changes.csv', params)
}

export const AuditReport: React.FunctionComponent<{ statics: IAuditPeriod[], adjustments: IAuditPeriod[] }> = ({ statics, adjustments }) => {
    const audits = statics.map(s => ({...s, type: 'Static changes'}))
        .concat(adjustments.map(a=> ({...a, type: 'Adjustments'})))
    const sorted = audits.sort((a, b) => (b.year * 100 + b.month) - (a.year * 100 + a.month))
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    return (
        <div style={{ marginTop: 200 }}>
            <SubHeader title='Audit Reports' />
            
            <div className='table isSmall' style={{ marginBottom:32}}>
                <div className='table--col' style={{ flexGrow: 1 }}>
                    {sorted.map(audit => (
                        <div key={'1_' + (audit.year * 100 + audit.month)} className='table--cell isFirst'>
                            <p>{(months[audit.month - 1] || '---') + ' ' + audit.year}</p>
                        </div>
                    ))}
                </div>

                <div className='table--col' style={{ flexGrow: 1 }}>
                    {sorted.map(audit =>
                        <div key={'2_' + (audit.year * 100 + audit.month)} className='table--cell'>
                            <p>{audit.type}</p>
                        </div>
                    )}
                </div>

                <div className='table--col' style={{ flexGrow: 1 }}>
                    {sorted.map(audit => (
                        <div key={'3_' + (audit.year * 100 + audit.month)} className='table--cell isLast'>
                            {audit.type === 'Adjustments' ? (
                                <p><a href='##' onClick={() => exportAdjustments(audit)}>Download</a></p>
                            ) : (
                                <p><a href='##' onClick={() => exportStaticChanges(audit)}>Download</a></p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
