import * as Sentry from '@sentry/browser';
import auth0, * as Auth0 from 'auth0-js'
import cookies from 'js-cookie'
import throttle from 'lodash.throttle'
import jwtDecode from 'jwt-decode'
import { IConfig } from './config'


const location = window.location;
interface IdTokenPayload {
    nickname: string,
    name: string,
    picture: string,
    updated_at: Date,
    iss: string,
    sub: string,
    aud: string,
    iat: number,
    exp: number,
    at_hash: string,
    nonce: string,
    'https://jarden.co.nz/upn': string,
}

class AuthServiceStaticHelper {
    static hasHash() {
        return location.hash.includes('access_token=')
    }

    static hasError() {
        const error = location.hash.includes('error=')
        if (error) {
            console.debug('AuthServiceStaticHelper.hasError', error)
        }
        return error
    }

    static getIdToken() {
        return localStorage.getItem('idToken')
    }

    static getAccessToken() {
        return localStorage.getItem('accessToken')
    }

    static getExpire() {
        return localStorage.getItem('tokenExpireAt')
    }

    static getIdTokenPayload(): IdTokenPayload | null {
        const idToken = AuthServiceStaticHelper.getIdToken()
        if (!idToken) return null
        return jwtDecode(idToken)
    }

    static getAccessTokenPayload() {
        const idToken = AuthServiceStaticHelper.getIdToken()
        if (!idToken) return null
        return jwtDecode(idToken)
    }

    static isExpiring() { // 30 minutes
        // Check whether the current time is past the
        // Access Token's expiry time
        const tokenExpires = AuthServiceStaticHelper.getExpire()
        if (!tokenExpires) {
            return true
        }
        const tokenExpiresIn = +new Date(tokenExpires) - +new Date()
        const isExpiring = tokenExpiresIn < 30 * 60 * 1000
        if (isExpiring) console.debug('tokenExpiresIn', tokenExpiresIn / 1000 / 60, 'minutes')
        return isExpiring
    }

    static isExpired() {
        const tokenExpires = AuthServiceStaticHelper.getExpire()
        if (!tokenExpires) {
            return true
        }
        const tokenExpiresIn = +new Date(tokenExpires) - +new Date()
        return tokenExpiresIn < 0
    }

    static isAuthenticated() {
        // Check whether the current time is past the
        // Access Token's expiry time
        const accessToken = AuthServiceStaticHelper.getAccessToken()
        return !!accessToken
    }

    static setUserContext() {
        const payload = AuthServiceStaticHelper.getIdTokenPayload()
        if (payload !== null) {
            Sentry.setUser({
                id: payload.sub,
                email: payload['https://jarden.co.nz/upn']
            })
        }
    }
}

export class AuthService {
    public webAuth: auth0.WebAuth;
    public helper: AuthServiceStaticHelper

    constructor(webAuthOptions: Auth0.AuthOptions, private config: IConfig) {
        this.webAuth = new auth0.WebAuth(webAuthOptions)
        this.setAuth0Login = throttle(this.setAuth0Login.bind(this), 3000)
        this.helper = AuthServiceStaticHelper
        if (!this.config.skipAuth0) {
            setInterval(this.renewIfExpiring.bind(this), 60 * 1000) // check expiring every 1 minutes
        }
    }

    resetToken() {
        this.clearTokens();
        this.logout();
    }

    renewIfExpiring() {
        if (AuthServiceStaticHelper.isAuthenticated() && AuthServiceStaticHelper.isExpiring()) {
            console.debug('renewing token')
            this.renewTokens(false)
        }
    }

    login() {
        this.webAuth.authorize()
    }

    handleAuthentication() {
        return new Promise((resolve, reject) => {
            this.webAuth.parseHash((err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.localLogin(authResult)
                    location.href = location.origin
                    resolve(true)
                } else if (err) {
                    setTimeout(() => resolve(false))
                    console.debug('handleAuthentication err', err)
                }
            })
        })
    }

    localLogin(authResult: Auth0.Auth0Result) {
        const expireAt = new Date(Date.now() + authResult.expiresIn! * 1000)
        localStorage.setItem('accessToken', authResult.accessToken!)
        localStorage.setItem('idToken', authResult.idToken!)
        localStorage.setItem('tokenExpireAt', expireAt.toISOString())
        cookies.set('accessToken', authResult.accessToken!, { expires: expireAt, secure: !this.config.isLocalDev })
    }

    isCookiesValid() {
        const accessTokenFromLocalStorage = AuthServiceStaticHelper.getAccessToken()
        const accessTokenFromCookies = cookies.get('accessToken')
        return !!accessTokenFromCookies && accessTokenFromCookies === accessTokenFromLocalStorage
    }

    renewTokens(shouldRefresh: boolean) {
        this.clearTokens();
        this.webAuth.checkSession({},
            (err, result) => {
                Sentry.addBreadcrumb({ message: 'renew token' })
                if (err) {
                    console.debug('err', err)
                    Sentry.captureException(err)
                    this.login()
                } else {
                    this.localLogin(result)
                    if (shouldRefresh) {
                        setTimeout(() => {
                            location.href = location.origin
                        }, 300)
                    }
                }
            }
        )
    }

    clearTokens() {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('idToken')
        localStorage.removeItem('tokenExpireAt')
        cookies.remove('accessToken')
    }

    logout() {
        this.clearTokens()
        this.webAuth.logout({
            returnTo: window.location.origin,
            federated: true,
        })
    }

    setAuth0Login() {
        if (AuthServiceStaticHelper.isAuthenticated()) {
            console.debug('Authenticated')
            AuthServiceStaticHelper.setUserContext()
            if (AuthServiceStaticHelper.isExpired()) {
                console.debug('isExpired re login')
                this.renewTokens(true)
            } else if (AuthServiceStaticHelper.isExpiring()) {
                console.debug('isExpiring renew token')
                this.renewTokens(false)
            } else if (!this.isCookiesValid()) {
                console.debug('cookies not valid')
                this.renewTokens(true)
            }
        } else if (AuthServiceStaticHelper.hasError()) {
            Sentry.captureMessage('login failed')
            throw Error('login failed') // todo
        } else if (AuthServiceStaticHelper.hasHash()) {
            // Handle the authentication
            // result in the hash
            console.debug('handleAuthentication')
            this.handleAuthentication()
        } else {
            console.debug('login user')
            this.login()
        }
    }

    getAccessToken() {
        return AuthServiceStaticHelper.getAccessToken()
    }

    getIdTokenPayload() {
        return AuthServiceStaticHelper.getIdTokenPayload()
    }
}

export const getAuthService = (config: IConfig) => {
    return new AuthService({
        clientID: config.auth0ClientID,
        audience: config.auth0Audience,
        domain: config.auth0Domain,
        responseType: 'token id_token',
        redirectUri: location.origin,
        scope: 'openid profile'
    }, config)
}
    
