import { ITableDataShape, ITableDataShapeMeta } from '../../utils/formatter'
import React from 'react'
import { TableHeader } from './TableHeader'
import { Index, MultiGrid } from 'react-virtualized'

interface ITableProps {
    headers: ITableDataShape[]
    tableData: ITableDataShape[][] 
}

const createCells = (tableData: ITableDataShape[][], columnCount: number) => {

    const renderHeader = (cellData: ITableDataShape) => {
        if (!cellData || cellData.filterOnly) return null
        const classNames = [
            (cellData.meta?.includes(ITableDataShapeMeta.rightAlign) ? 'isAlignRight' : ''),
            (cellData.meta?.includes(ITableDataShapeMeta.isOrange) ? 'isOrange' : ''),
            (cellData.meta?.includes(ITableDataShapeMeta.isYellow) ? 'isYellow' : ''),
            (cellData.meta?.includes(ITableDataShapeMeta.isGreen) ? 'isGreen' : ''),
            (cellData.meta?.includes(ITableDataShapeMeta.isTeal) ? 'isTeal' : '')]
        return <TableHeader className={`${classNames.join(' ')}`} orderDown={false} title={cellData.label} />
    }

    const renderCell = (cellData: ITableDataShape) => {
        return !cellData || cellData.filterOnly ? null : cellData.formatter(cellData.content)
    }

    return ({ columnIndex, rowIndex, style }: { columnIndex: number, rowIndex: number, style: React.CSSProperties }) => {
        const rowData = tableData[rowIndex].filter(d => !d.filterOnly)
        const cellData = rowData[columnIndex];

        const classNames = [
            'table--cell',
            (rowIndex === 0) ? 'isHeader' : '',
            (columnIndex === 0) ? 'isFirst' : '',
            (columnIndex === columnCount - 1) ? 'isLast' : '',
            (cellData.meta?.includes(ITableDataShapeMeta.isEven) ? 'isEven' : ''),
            (cellData.meta?.includes(ITableDataShapeMeta.isOdd) ? 'isOdd' : '')
        ]

        return (
            <div key={rowIndex + '-' + columnIndex + '-' + Math.random()} className={`${classNames.join(' ')}`} style={style} >
                {rowIndex === 0 ? renderHeader(cellData) : renderCell(cellData)}
            </div>
        )
    }
}

export const Table: React.FunctionComponent<ITableProps> = ({ headers, tableData }) => {

    // get the Windows Dimension
    const [winWidth, setWidth] = React.useState(window.innerWidth)
    const updateWidth = () => {
        setWidth(window.innerWidth)
    }
    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    })
    // Table width is windows width minus Sidebar, Padding Left and Right,
    const tableWidth = winWidth - 256 - 160

    // Set the width of each column. The columns overflow the table width in this UI
    const columnWidths = (col: Index) => {
        return headers[col.index].width || 160
    }

    const columnCount = headers.filter(d => !d.filterOnly).length
    const rowCount = tableData.length + 1
    const data: ITableDataShape[][] = [headers, ...tableData]

    return (
        <MultiGrid
            height={700}
            columnWidth={index => columnWidths(index)}
            columnCount={columnCount}
            width={tableWidth}
            rowCount={rowCount}
            rowHeight={() => 44}
            className="Grid gridContents"
            cellRenderer={createCells(data, columnCount)}
            fixedColumnCount={1}
            fixedRowCount={1}
        />
    )
}
