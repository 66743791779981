import { gql } from 'apollo-boost'
import { FilterDataTypes } from '../utils/filter'
import {
    dateFormatter,
    defaultFormatter,
    ITableDataShape,
    ITableDataShapeMeta,
    rightAlignFormatter
} from '../utils/formatter'


export const QUERY_TRANSACTIONS = gql`
    query getTransactions($limit: Int, $offset: Int, $filters: [Filter], $sorting: Sorting) {
        transactions(limit: $limit, offset: $offset, filters: $filters, sorting: $sorting) {
            transaction_id
            adjustment_reference
            transaction_type
            financial_year
            financial_month
            cp_code
            currency_code
            withhold_code
            ccy_gross_div
            ccy_rwt_div
            ccy_nrwt_div
            ccy_ic
            ccy_gross_int
            ccy_rwt_int
            ccy_nrwt_int
            ccy_gross_ail
            ccy_ail
            fx_rate
            fx_gross_div
            fx_rwt_div
            fx_nrwt_div
            fx_ic
            fx_gross_int
            fx_rwt_int
            fx_nrwt_int
            fx_gross_ail
            fx_ail
            movement_date
            created
        }
    }
`

export interface ITransaction {
    transaction_id: string
    adjustment_reference?: string
	transaction_type: string
    financial_year: number
	financial_month: number
	cp_code: string
    currency_code: string
    withhold_code: string
    ccy_gross_div: string
    ccy_rwt_div: string
    ccy_nrwt_div: string
    ccy_ic: string
    ccy_gross_int: string
    ccy_rwt_int: string
    ccy_nrwt_int: string
    ccy_gross_ail: string
    ccy_ail: string
    fx_rate: string
    fx_gross_div: string
    fx_rwt_div: string
    fx_nrwt_div: string
    fx_ic: string
    fx_gross_int: string
    fx_rwt_int: string
    fx_nrwt_int: string
    fx_gross_ail: string
    fx_ail: string
    movement_date?: string
    created: Date
}

export interface IGetTransaction {
    transactions: ITransaction[]
}

export const headerData: ITransaction = {
    transaction_id: '',
	transaction_type: '',
    financial_year: 0,
	financial_month: 0,
	cp_code: '',
    currency_code: '',
    withhold_code: '',
    ccy_gross_div: '',
    ccy_rwt_div: '',
    ccy_nrwt_div: '',
    ccy_ic: '',
    ccy_gross_int: '',
    ccy_rwt_int: '',
    ccy_nrwt_int: '',
    ccy_gross_ail: '',
    ccy_ail: '',
    fx_rate: '',
    fx_gross_div: '',
    fx_rwt_div: '',
    fx_nrwt_div: '',
    fx_ic: '',
    fx_gross_int: '',
    fx_rwt_int: '',
    fx_nrwt_int: '',
    fx_gross_ail: '',
    fx_ail: '',
    adjustment_reference: '',
    created: new Date()
};

const convert = (adjustments: IAdjustment[]): ITableDataShape[][] => {
    return adjustments.map(adjustment => {
        const transaction = adjustment.transaction
        const meta: ITableDataShapeMeta[] = adjustment.isOdd === undefined ? [] : [adjustment.isOdd ? ITableDataShapeMeta.isOdd : ITableDataShapeMeta.isEven]
        return [{
            label: 'Type',
            dataType: FilterDataTypes.string,
            content: transaction.transaction_type,
            value: 'transaction_type',
            width: 150,
            meta: meta,
            formatter: defaultFormatter
        },
        {
            label: 'Fin. Year',
            dataType: FilterDataTypes.number,
            content: transaction.financial_year,
            value: 'financial_year',
            width: 80,
            meta: meta,
            formatter: defaultFormatter
        },
        {
            label: 'Fin. Month',
            dataType: FilterDataTypes.number,
            content: transaction.financial_month,
            value: 'financial_month',
            width: 80,
            meta: meta,
            formatter: defaultFormatter
        },
        {
            label: 'CP Code',
            dataType: FilterDataTypes.string,
            content: transaction.cp_code,
            value: 'cp_code',
            width: 120,
            meta: meta,
            formatter: defaultFormatter
        },
        {
            label: 'Curr.',
            dataType: FilterDataTypes.string,
            content: transaction.currency_code,
            value: 'currency_code',
            width: 80,
            meta: meta,
            formatter: defaultFormatter
        },
        {
            label: 'WT Code',
            dataType: FilterDataTypes.string,
            content: transaction.withhold_code,
            value: 'withhold_code',
            meta: meta,
            formatter: defaultFormatter
        },
        {
            label: 'CCY Gross div',
            dataType: FilterDataTypes.number,
            content: transaction.ccy_gross_div,
            value: 'ccy_gross_div',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isOrange],
            formatter: rightAlignFormatter
        },
        {
            label: 'CCY RWT div',
            dataType: FilterDataTypes.number,
            content: transaction.ccy_rwt_div,
            value: 'ccy_rwt_div',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isOrange],
            formatter: rightAlignFormatter
        },
        {
            label: 'CCY NRWT div',
            dataType: FilterDataTypes.number,
            content: transaction.ccy_nrwt_div,
            value: 'ccy_nrwt_div',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isOrange],
            formatter: rightAlignFormatter
        },
        {
            label: 'CCY IC',
            dataType: FilterDataTypes.number,
            content: transaction.ccy_ic,
            value: 'ccy_ic',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isOrange],
            formatter: rightAlignFormatter
        },
        {
            label: 'CCY Gross int',
            dataType: FilterDataTypes.number,
            content: transaction.ccy_gross_int,
            value: 'ccy_gross_int',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isYellow],
            formatter: rightAlignFormatter
        },
        {
            label: 'CCY RWT int',
            dataType: FilterDataTypes.number,
            content: transaction.ccy_rwt_int,
            value: 'ccy_rwt_int',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isYellow],
            formatter: rightAlignFormatter
        },
        {
            label: 'CCY NRWT int',
            dataType: FilterDataTypes.number,
            content: transaction.ccy_nrwt_int,
            value: 'ccy_nrwt_int',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isYellow],
            formatter: rightAlignFormatter
        },
        {
            label: 'CCY Gross AIL',
            dataType: FilterDataTypes.number,
            content: transaction.ccy_gross_ail,
            value: 'ccy_gross_ail',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isYellow],
            formatter: rightAlignFormatter
        },
        {
            label: 'CCY AIL',
            dataType: FilterDataTypes.number,
            content: transaction.ccy_ail,
            value: 'ccy_ail',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isYellow],
            formatter: rightAlignFormatter
        },
        {
            label: 'Fx Rate',
            dataType: FilterDataTypes.number,
            content: transaction.fx_rate,
            value: 'fx_rate',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isGreen],
            formatter: rightAlignFormatter
        },
        {
            label: 'FX Gross div',
            dataType: FilterDataTypes.number,
            content: transaction.fx_gross_div,
            value: 'fx_gross_div',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isTeal],
            formatter: rightAlignFormatter
        },
        {
            label: 'FX RWT div',
            dataType: FilterDataTypes.number,
            content: transaction.fx_rwt_div,
            value: 'fx_rwt_div',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isTeal],
            formatter: rightAlignFormatter
        },
        {
            label: 'FX NRWT div',
            dataType: FilterDataTypes.number,
            content: transaction.fx_nrwt_div,
            value: 'fx_nrwt_div',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isTeal],
            formatter: rightAlignFormatter
        },
        {
            label: 'FX IC',
            dataType: FilterDataTypes.number,
            content: transaction.fx_ic,
            value: 'fx_ic',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isTeal],
            formatter: rightAlignFormatter
        },
        {
            label: 'FX Gross int',
            dataType: FilterDataTypes.number,
            content: transaction.fx_gross_int,
            value: 'fx_gross_int',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isGreen],
            formatter: rightAlignFormatter
        },
        {
            label: 'FX RWT int',
            dataType: FilterDataTypes.number,
            content: transaction.fx_rwt_int,
            value: 'fx_rwt_int',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isGreen],
            formatter: rightAlignFormatter
        },
        {
            label: 'FX NRWT int',
            dataType: FilterDataTypes.number,
            content: transaction.fx_nrwt_int,
            value: 'fx_nrwt_int',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isGreen],
            formatter: rightAlignFormatter
        },
        {
            label: 'FX Gross AIL',
            dataType: FilterDataTypes.number,
            content: transaction.fx_gross_ail,
            value: 'fx_gross_ail',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isGreen],
            formatter: rightAlignFormatter
        },
        {
            label: 'FX AIL',
            dataType: FilterDataTypes.number,
            content: transaction.fx_ail,
            value: 'fx_ail',
            meta: [...meta, ITableDataShapeMeta.rightAlign, ITableDataShapeMeta.isGreen],
            formatter: rightAlignFormatter
        },
        {
            label: 'MOV. Date',
            dataType: FilterDataTypes.date,
            content: transaction.movement_date,
            value: 'movement_date',
            meta: meta,
            formatter: dateFormatter
        },
    ]})
}


export interface IAdjustment {
    transaction: ITransaction,
    isOdd?: boolean
}


export const transactionFilterFields = (): ITableDataShape[] => convert([{transaction: headerData}])[0]

export const transactionTableDataMaker = (transactions: ITransaction[]): ITableDataShape[][] => convert(transactions.map(t => ({transaction: t})))

export const adjustmentTableDataMaker = (adjustments: IAdjustment[]): ITableDataShape[][] => convert(adjustments)


