import React from 'react'
import { v1 as uuid } from 'uuid'
import { Button, IconButton, Input, SelectSingle } from '@jarden-digital/component-lib'
import { Add, Clear, Search } from '@material-ui/icons'
import * as R from 'ramda'
import {
    FilterCommand,
    FilterDataTypes,
    getFiltererCommandByCommandType,
    getFilterTypeByDataType,
    IFilter,
    IFilterCommand
} from '../../utils/filter'

export interface ISelectEntry {
    value: string
    label: string
}

export interface IFilterSelectEntry extends ISelectEntry {
    value: string
    label: string
    dataType: FilterDataTypes
}

interface IFiltersProps {
    fields: IFilterSelectEntry[]
    filterDraft: IFilter[]
    filterApplied: IFilter[]
    setFilterDraft: React.Dispatch<React.SetStateAction<IFilter[]>>
    setFilterApplied: React.Dispatch<React.SetStateAction<IFilter[]>>
}

interface IFilterRowProps {
    fields: IFilterSelectEntry[]

    filter: IFilter
    changeFilter: (change: { [key: string]: string }) => void
}

export const createEmptyFilter = (): IFilter => ({ id: uuid(), field: '', command: FilterCommand.null, value: '' })

const isCommandWithoutValue = (command: FilterCommand): boolean => {
    return [FilterCommand.empty, FilterCommand.notEmpty, FilterCommand.isZero, FilterCommand.notZero].includes(command)
}

const filterVerifier = (filters: IFilter[]): { id: string, attrs: string[] }[] => {
    return R.reduce<IFilter, ({ id: string, attrs: string[] })[]>((acc, filter) => {
        const errors = []
        if (!filter.field) errors.push('field')
        if (filter.command === FilterCommand.null) errors.push('command')
        if ([FilterCommand.dateMatch, FilterCommand.dateAfter, FilterCommand.dateBefore].includes(filter.command) && filter.value.length < 8) errors.push('value')
        if (!isCommandWithoutValue(filter.command) && (filter.value === '' || filter.value == null)) errors.push('value')
        return errors.length > 0 ? [...acc, { id: filter.id, attrs: errors }] : acc
    }, [] as { id: string, attrs: string[] }[], filters)
}

const FilterRow: React.FunctionComponent<IFilterRowProps> = ({ filter, changeFilter, fields, children }) => {

    const addEmpty = (options: ISelectEntry[]) =>
        (options[0]?.value === 'null') ? options : [{ value: 'null', label: '' }].concat(options)

    const getMethodByField = (fieldValue: string, fields: IFilterSelectEntry[]): FilterCommand[] => {
        const foundField = fields.find((f) => f.value === fieldValue)
        if (!foundField) return []
        const { dataType } = foundField
        const { allowedCommands } = getFilterTypeByDataType(dataType)
        return allowedCommands
    }

    const getCommandMaps = (commands: FilterCommand[]): IFilterCommand[] => commands.map(getFiltererCommandByCommandType)

    const commandOptions = addEmpty(getCommandMaps(getMethodByField(filter.field, fields)))
    const isNoneEditable = !!filter.extraType;

    return (
        <div className="gridContents">
            <div style={{
                opacity: isNoneEditable ? 0.3 : 1,
                pointerEvents: isNoneEditable ? 'none' : 'all'
            }} className='Filters--fillCollection'>
                <SelectSingle
                    label="Select a field"
                    value={filter.field}
                    options={addEmpty(fields)}
                    onChange={field => changeFilter({ field: field })}
                />
                <SelectSingle
                    placeholder="Contains"
                    label="Compute"
                    value={filter.command}
                    options={commandOptions}
                    onChange={command => changeFilter({ command: command })}
                />
                <Input
                    placeholder={ isCommandWithoutValue(filter.command) ? "------------------------------------" : "Type here"}
                    value={ filter.value }
                    label={ isCommandWithoutValue(filter.command) ? "N/A" : "Value" }
                    onChange={value => changeFilter({ value: value })}
                />
            </div>
            {children}
        </div>
    )
}

export const Filters: React.FunctionComponent<IFiltersProps> = ({ fields, filterDraft, filterApplied, setFilterDraft, setFilterApplied }) => {

    const filterErrors = filterVerifier(filterDraft)

    const addFilter = () => setFilterDraft(filterDraft.concat(createEmptyFilter()))
    const removeFilter = (id: string) => setFilterDraft(filterDraft.filter(f => f.id !== id))

    const search = () => {
        setFilterApplied(filterDraft)
    }

    const onFilterChange = (id: string, change: { [key: string]: string }) => {
        setFilterDraft(filterDraft.map(f => f.id === id ? { ...f, ...change } : f))
    }

    const resetFilters = () => {
        const extraFilters = filterDraft.filter(f => !!f.extraType)
        setFilterDraft([...extraFilters, createEmptyFilter()])
        setFilterApplied(extraFilters)
    }

    const filterExcludedSpecial = filterDraft.filter(f => !f.extraType) // you can disable it for debugging

    const createFilter = (filter: IFilter, index: number) => {
        return (
            <div className="grid" key={index}>
                {filterExcludedSpecial.length > 1 ? (
                    <div style={{visibility: filter.extraType ? 'hidden' : 'visible'}} 
                        className='Filters--clearCollection gridActions' 
                        onClick={() => removeFilter(filter.id)}>
                        <Clear/>
                    </div>
                ) : null}
                <FilterRow
                    filter={filter}
                    fields={fields.map(f => ({...f, label: f.label.toUpperCase()}))}
                    changeFilter={change => onFilterChange(filter.id, change)}
                >
                    <div className={index === filterExcludedSpecial.length - 1 ? 'Filters--buttons' : 'Filters--buttons hidden'}>
                        <IconButton secondary={true} outline={true} onClick={addFilter}>
                            <Add/>
                        </IconButton>
                        <IconButton disabled={filterErrors.length > 0} secondary={false} outline={true}
                                    onClick={search}>
                            <Search/>
                        </IconButton>
                        <Button secondary={true} outline={true} onClick={resetFilters}>
                            Reset
                        </Button>
                    </div>
                </FilterRow>
            </div>
        )
    }

    return (
        <div className='Filters'>
            {filterExcludedSpecial?.
            map(createFilter)}
        </div>
    )
}
