import React from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Dashboard as DashboardIcon } from '@material-ui/icons'
import { Card, Upload, ScreenTitle, IRDMonthlyReport, PayableToIRD } from '../components'
import { QUERY_DASHBOARD, IGetDashboardData } from '../api/dashboard'
import { getCountForName } from '../utils/common'
import { AuditReport } from '../components/framework/AuditReport'


export const Dashboard: React.FunctionComponent = () => {
    const history = useHistory();

    const { loading, error, data } = useQuery<IGetDashboardData>(QUERY_DASHBOARD)

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>


    const counters = data?.dashboard.counters || []
    const se_count = getCountForName(counters, 'static_exceptions')
    const te_count = getCountForName(counters, 'transactional_exceptions')

    const reports = data?.dashboard.reports || []
    const payables = data?.dashboard.payables || []
    const audit_statics = data?.dashboard.audit_statics || []
    const adjustments = data?.dashboard.audit_adjustments || []

    return (
        <main className='Dashbaord layoutRightAside' style={{paddingBottom: 0}}>
            <section>
                <ScreenTitle 
                    title="Dashboard" 
                    icon={<DashboardIcon />} />
 
                <div className="grid">
                    <div className="containFilters gridContents">
                        <Card title="All Exceptions"
                            count={se_count + te_count}
                            active={true} />
                        <Card title="Static Exceptions" 
                            count={se_count} 
                            onClick={() =>{history.push('/static')}} />
                        <Card title="Transaction Exceptions" 
                            count={te_count}
                            onClick={() =>{history.push('/transactional')}} />
                        <Upload />
                    </div>
                </div>
                <PayableToIRD payables={payables} />
            </section>
            <aside>
                <IRDMonthlyReport reports={reports} />
                <AuditReport statics={audit_statics} adjustments={adjustments} />
            </aside>
        </main>
    )
}
