import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Table, Column } from 'react-virtualized'
import { IClient, IGetClients, QUERY_CLIENTS } from '../api/clients'
import { IFilter } from '../utils/filter'


const borderWidth = 2

const getColumnText = (client: IClient, index: number) => {
    switch (index) {
        case 0: return <p>{client.cp_code}</p>
        case 1: return <p>{client.cp_name}</p>
        default: return ''
    }
}

export const ClientsSummaryTable: React.FunctionComponent<{ filters: IFilter[] }> = () => {

    // get the Windows Dimension
    const [winWidth, setWidth] = React.useState(window.innerWidth)
    const updateWidth = () => {
        setWidth(window.innerWidth)
    }
    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    })
    // Table width is windows width minus Sidebar, Padding Left and Right,
    const tableWidth = winWidth - 256 - 160

    const { loading, error, data } = useQuery<IGetClients>(QUERY_CLIENTS);

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>

    const clients = data?.clients || []

    return (
        <div>
            <Table
                width={tableWidth}
                height={480}
                headerHeight={44}
                rowHeight={44}
                rowClassName={'table--row'}
                headerClassName=" isHeader table--cell"
                rowCount={clients.length}
                rowGetter={({ index }) => clients[index] || {}}>
                <Column
                    label="cp code"
                    dataKey="exception"
                    className="table--cell"
                    cellRenderer={args => getColumnText(args.rowData, 0)}
                    width={tableWidth * .15 - borderWidth}
                />
                <Column
                    label="cp name"
                    dataKey="cp_code"
                    className="table--cell"
                    cellRenderer={args => getColumnText(args.rowData, 1)}
                    width={tableWidth * .15}
                />
            </Table>
        </div>
    )
}
