import { gql } from 'apollo-boost'
import { FilterDataTypes } from '../utils/filter'
import {
    dateFormatter,
    defaultFormatter,
    ITableDataShape,
    ITableDataShapeMeta,
    rightAlignFormatter
} from '../utils/formatter'

export const QUERY_TERM_DEPOSITS = gql`
    query getTermDeposits($limit: Int, $offset: Int, $filters: [Filter], $sorting: Sorting) {
        term_deposits(limit: $limit, offset: $offset, filters: $filters, sorting: $sorting) {
            id
            cp_code
            account_id
            movement_pk
            financial_year
            financial_month
            currency_code
            movement_date
            description
            debit_amount
            credit_amount
            net_amount
            gross_interest_income
            withhold_code
        }
    }
`

export interface ITermDeposit {
    id: string
    cp_code: string
    account_id?: string
    movement_pk?: string
	financial_year: number
	financial_month: number
	currency_code?: string
	movement_date?: string
	description?: string
	debit_amount?: string
	credit_amount?: string
    net_amount?: string
    gross_interest_income?: string
	withhold_code?: string
}

export interface IGetTermDeposits {
    term_deposits: ITermDeposit[]
}

const headerData: ITermDeposit = {
    id: '',
    cp_code: '',
    financial_month: 0,
    financial_year: 0
}

const convert = (termDeposits: ITermDeposit[]): ITableDataShape[][] => {
    return termDeposits.map(term_deposit => {
        return [
            {
                label: 'cp Code',
                dataType: FilterDataTypes.string,
                content: term_deposit.cp_code,
                value: 'cp_code',
                width: 120,
                formatter: defaultFormatter
            },
            {
                label: 'Fin. Year',
                dataType: FilterDataTypes.number,
                content: term_deposit.financial_year,
                value: 'financial_year',
                width: 80,
                formatter: defaultFormatter
            },
            {
                label: 'Fin. Month',
                dataType: FilterDataTypes.number,
                content: term_deposit.financial_month,
                value: 'financial_month',
                width: 80,
                formatter: defaultFormatter
            },
            {
                label: 'Curr.',
                dataType: FilterDataTypes.string,
                content: term_deposit.currency_code,
                value: 'currency_code',
                width: 120,
                formatter: defaultFormatter
            },
            {
                label: 'Description',
                dataType: FilterDataTypes.string,
                content: term_deposit.description,
                value: 'description',
                width: 420,
                formatter: defaultFormatter
            },
            {
                label: 'Debit',
                dataType: FilterDataTypes.number,
                content: term_deposit.debit_amount,
                value: 'debit_amount',
                meta: [ITableDataShapeMeta.rightAlign],
                formatter: rightAlignFormatter
            },
            {
                label: 'Credit',
                dataType: FilterDataTypes.number,
                content: term_deposit.credit_amount,
                value: 'credit_amount',
                meta: [ITableDataShapeMeta.rightAlign],
                formatter: rightAlignFormatter
            },
            {
                label: 'Net',
                dataType: FilterDataTypes.number,
                content: term_deposit.net_amount,
                value: 'net_amount',
                meta: [ITableDataShapeMeta.rightAlign],
                formatter: rightAlignFormatter
            },
            {
                label: 'Gross Inc.',
                dataType: FilterDataTypes.number,
                content: term_deposit.gross_interest_income,
                value: 'gross_interest_income',
                formatter: rightAlignFormatter
            },
            {
                label: 'WT. Code',
                dataType: FilterDataTypes.string,
                content: term_deposit.withhold_code,
                value: 'withhold_code',
                formatter: defaultFormatter
            },
            {
                label: 'MOV. PK',
                dataType: FilterDataTypes.string,
                content: term_deposit.movement_pk,
                value: 'movement_pk',
                formatter: defaultFormatter
            },
            {
                label: 'MOV. Date',
                dataType: FilterDataTypes.date,
                content: term_deposit.movement_date,
                value: 'movement_date',
                formatter: dateFormatter
            },
        ]
    })
}


export const termDepositsFilterFields = (): ITableDataShape[] => convert([headerData])[0]

export const termDepositsTableDataMaker = (termDeposits: ITermDeposit[]): ITableDataShape[][] => convert(termDeposits)
