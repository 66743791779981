import { gql } from 'apollo-boost'
import { ICounter } from '../utils/common'

export const QUERY_TOTAL_COUNTS = gql`
    query getTotalCounts {
        total_counts {
            name
            count
        }
    }
`

export interface IGetTotalCounts {
    total_counts: ICounter[]
}

