import { gql } from 'apollo-boost'
import { ICounter } from '../utils/common'


export const QUERY_STATIC_EXCEPTIONS= gql`
    query getStaticExceptions($limit: Int, $offset: Int, $filters: [Filter], $sorting: Sorting) {
        static_exceptions(limit: $limit, offset: $offset, filters: $filters, sorting: $sorting) {
            id
            cp_code
            cp_name
            cp_class
            exception
            created
            accounts {
                account_id
                investor_type
                tax_number
                withhold_code
            }
            contacts {
                contact_id
                first_name
                last_name
                email_address
                tax_number
                address1
                address2
                address3
                city_district
                postal_code
                country
                country_iso_code
            }
        }
    }
`

export interface IStaticException {
    id: string
    cp_code: string
    cp_name: string
    cp_class: string
    exception: string
    accounts: IAccount[]
    contacts: IContact[]
    created: string
}

export interface IAccount {
    account_id: string
    investor_type: string
    tax_number?: string
    withhold_code?: string
}

export interface IContact {
    contact_id: string
    first_name?: string
    last_name?: string
    email_address?: string
    tax_number?: string
    address1?: string
    address2?: string
    address3?: string
    city_district?: string
    postal_code?: string
    country?: string
    country_iso_code?: string
}

export interface IGetStaticException {
    static_exceptions: IStaticException[]
}


export const QUERY_STATIC_COUNTS= gql`
    query getStaticCounts($names: [String]) {
        static_counts(names: $names) {
            name
            count
        }
    }
`

export interface IGetStaticCounts {
    static_counts: ICounter[]
}


