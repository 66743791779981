import React from 'react'
import { ArrowUpward } from '@material-ui/icons'
import { uploadCSV } from '../../utils/common'


export const Upload: React.FunctionComponent = () => {
    const inputId = 'csv-upload-input'

    const upload = (e: React.ChangeEvent) => {
        const files = (e.target as HTMLInputElement).files
        if (!!files && files.length > 0) {
            uploadCSV(files[0]).then(response => {
                if (response.ok) {
                     window.location.reload()
                } else {
                    response.text().then(error => alert(error))
                }
            })
        }
    }

    return (
        <div className="Upload" onClick={() => { document.getElementById(inputId)?.click() }}>
            <span className="Upload--icon"><ArrowUpward fontSize="large" /></span>
            <span className="Upload--text">Upload</span>
            <input type="file" id={inputId} hidden={true} name="file" accept=".csv" onChange={upload} />
        </div>
    )
}