import { gql } from 'apollo-boost'
import { ICounter } from '../utils/common'


export const QUERY_TRANSACTIONAL_EXCEPTIONS= gql`
    query getTransactionalExceptions($limit: Int, $offset: Int, $filters: [Filter], $sorting: Sorting) {
        transactional_exceptions(limit: $limit, offset: $offset, filters: $filters, sorting: $sorting) {
            id
	        source
	        source_id
	        cp_code
			financial_year
			financial_month
	        withhold_code
	        rate
	        gross
	        net
	        calc_net
			diff 
	        currency_code  
        }
    }
`

export interface ITransactionalException {
    id: string
	source: string
	source_id: string
	cp_code: string
    financial_year: number
    financial_month: number
	withhold_code: string
	rate: string
	gross: string
	net: string
	calc_net: string
	diff: string
	currency_code: string
}

export interface IGetTransactionalException {
    transactional_exceptions: ITransactionalException[]
}


export const QUERY_TRANSACTIONAL_COUNTS= gql`
    query getTransactionalCounts($names: [String]) {
        transactional_counts(names: $names) {
            name
            count
        }
    }
`

export interface IGetTransactionalCounts {
    transactional_counts: ICounter[]
}


