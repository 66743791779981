import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Dashboard, TableChartOutlined } from '@material-ui/icons'
import { AuthService } from '../../utils/auth0.service'
import { ReactComponent as ReactLogo }  from '../../assets/img/logo.svg'

interface ILink {
    to: string
    icon: JSX.Element
    title: string
    isSelected: (pathname: string) =>  boolean
}

interface ISideNaveProps {
    authService: AuthService
}

const links: ILink[] = [
    {
        to: '/',
        icon: (<Dashboard />),
        title: 'Dashboard',
        isSelected: (pathname: string) =>  pathname === "/" || pathname === "/static" || pathname === "/transactional"
    }, {
        to: '/builder',
        icon: (<TableChartOutlined />),
        title: 'View Builder',
        isSelected: (pathname: string) =>  pathname === "/builder"
    // }, {
    //     to: '/clients',
    //     icon: (<PeopleOutlined />),
    //     title: 'Client Summary',
    //     isSelected: (pathname: string) =>  pathname === "/clients"
    }
]

export const SideNav: React.FunctionComponent<ISideNaveProps> = ({ authService }) => {
    const location = useLocation();
    const payload = authService.getIdTokenPayload();

    const createLink = (link: ILink, i: number) => (
        <Link key={i} to={link.to} className={link.isSelected(location.pathname) ? 'SideNav--link isSelected' : 'SideNav--link'} >
            {link.icon}
            <span>{link.title}</span>
        </Link>
    )

    const logout = !payload ? null : (
        <a href="##" onClick={() => authService.logout()} className="SideNav--link userinfo">
            <img alt="avatar" style={{ height: 48, width: 48, borderRadius: 24, marginRight: 16 }} src={payload?.picture}/>
            {/* <span> {payload?.nickname}</span> */}
            <span>Logout</span>
        </a>
    )

    return (
        <nav className="SideNav">
            <div className="logo"><ReactLogo/></div>
            {links.map(createLink)}
            {logout}
        </nav>
    )
}

