import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'

import { ApolloProvider } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-boost';

import './Styles/index.scss'
import App from './App'

import { getAuthService, AuthService } from './utils/auth0.service'
import { fetchConfig, IConfig } from './utils/config'


const getApolloClient = ((config: IConfig, auth: AuthService) => {
    const authLink = setContext((_, { headers }) => {
        const token = auth.getAccessToken();
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        };
    });
    const httpLink = createHttpLink({ uri: config.api_url + '/graphql' });

    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });   
})

fetchConfig.then(config => {
    const auth = getAuthService(config)
    auth.setAuth0Login()

    ReactDOM.render(
        <ApolloProvider client={getApolloClient(config, auth)}>
            <App authService={auth} />
        </ApolloProvider>
    , document.getElementById('root'))
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
