import * as React from 'react'
import { Button } from '@jarden-digital/component-lib'
import { SubHeader } from './SubHeader'
import { downloadCSV, createReport } from '../../utils/common'
import { IReportLink } from '../../api/dashboard'


const onExportClick = (params: IReportLink) => {
    downloadCSV('rwt.csv', params)
    downloadCSV('ips.csv', params)
    downloadCSV('nrwt.csv', params)
    downloadCSV('ail.csv', params)
}

const PopupDialog: React.FunctionComponent<{ year: number, month: number, onClose: () => void }> = ({ year, month, onClose }) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const onCreateClick = () => {
        onClose()
        return createReport(year, month).then(response => {
            if (response.ok) {
                 window.location.reload()
            } else {
                response.text().then(error => alert(error))
            }
        })
    }

    return (
        <div className='PopupDialog'>
            <div className='PopupDialog-box'>
                <div className='PopupDialog--content'>
                    <h3 className='header01' style={{marginBottom:32}}>Archive</h3>
                    <p className='subhead02' style={{marginBottom:16}}>Are you sure you want to archive the IRD report for { months[month - 1] || '---' } { year }?</p>
                    <p>You will not be able to edit this report anymore, and this version will be stored for audit purposes.</p>
                </div>
                <div className='PopupDialog--footer'>
                    <Button  onClick={onCreateClick}>Confirm</Button>
                    <div style={{marginRight:16}} />
                    <Button secondary={true} outline={true} onClick={onClose}>Cancel</Button>
                </div>
            </div>
        </div>
    )
}

export const IRDMonthlyReport: React.FunctionComponent<{ reports: IReportLink[] }> = ({ reports }) => {
    const [ popup, setPopup ] = React.useState<{year: number, month: number} | null>(null)
    const sorted = reports.sort((a, b) => (b.year * 100 + b.month) - (a.year * 100 + a.month))
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    return (
        <div className='IRDMonthlyReport' style={{ marginTop: 200 }}>
            <SubHeader title='IRD Reports' />
            
            <div className='table isSmall' style={{ marginBottom:32}}>
                <div className='table--col' style={{ flexGrow: 1 }}>
                    {sorted.map(report => (
                        <div key={'1_' + (report.year * 100 + report.month)} className='table--cell isFirst'>
                            <p>{(months[report.month - 1] || '---') + ' ' + report.year}</p>
                        </div>
                    ))}
                </div>

                <div className='table--col' style={{ flexGrow: 1 }}>
                    {sorted.map(report =>
                        report.draft ? (
                            <div key={'2_' + (report.year * 100 + report.month)} className='table--cell isAlert'><p>Pending</p></div>
                        ) : (
                            <div key={'2_' + (report.year * 100 + report.month)} className='table--cell'><p>Done </p></div>
                        )
                    )}
                </div>

                <div className='table--col' style={{ flexGrow: 1 }}>
                    {sorted.map(report => (
                        <div key={'3_' + (report.year * 100 + report.month)} className='table--cell isLast'>
                            <p><a href='##' onClick={() => onExportClick(report)}>Download</a></p>
                        </div>
                    ))}
                </div>
            </div>
            {sorted.filter(r => r.draft).map(report => (
                <span style={{marginRight: 20}}>
                    <Button secondary={true} onClick={() => setPopup(report)}>
                        Archive {(months[report.month -1] || '---') + ' ' + report.year }
                    </Button>
                </span>
            ))}
            { popup ? (
                <PopupDialog year={popup.year} month={popup.month} onClose={() => setPopup(null)} />
            ) : null }
        </div>
    )
}
