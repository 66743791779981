import React, { useState } from 'react'
import { v1 as uuid } from 'uuid'
import { Dashboard as DashboardIcon } from '@material-ui/icons'
import { useQuery } from '@apollo/react-hooks'
import { Button } from '@jarden-digital/component-lib'
import { QUERY_TRANSACTIONAL_EXCEPTIONS, IGetTransactionalException, QUERY_TRANSACTIONAL_COUNTS, IGetTransactionalCounts } from '../api/transactional-exceptions'
import { Card, ScreenTitle, Filters, IFilterSelectEntry, createEmptyFilter } from '../components'
import { TExceptionsTable } from '../contexts'
import { FilterDataTypes, IFilter, FilterCommand } from '../utils/filter'
import { downloadCSV, getCountForName, ICounter } from '../utils/common'


const cards = [
    { type: 'all', title: 'All Exceptions' },
    { type: 'cmt', title: 'Incorrect tax (CMT)' },
    { type: 'dividend', title: 'Incorrect tax (Dividends)' },
    { type: 'term-deposit', title: 'Incorrect tax (Term Deposits)' },
    { type: 'manual', title: 'Incorrect tax (Manual)' },
    { type: 'adjustment', title: 'Incorrect tax (Adjustment)' }
]

const fields: IFilterSelectEntry[] = [
    { label: 'CP Code', value: 'cp_code', dataType: FilterDataTypes.string },
    { label: 'Fin. year', value: 'financial_year', dataType: FilterDataTypes.number },
    { label: 'Fin. month', value: 'financial_month', dataType: FilterDataTypes.number },
    { label: 'Withhold Code', value: 'withhold_code', dataType: FilterDataTypes.string },
    { label: 'Tax rate', value: 'rate', dataType: FilterDataTypes.number },
    { label: 'Gross', value: 'gross', dataType: FilterDataTypes.number },
    { label: 'Net', value: 'net', dataType: FilterDataTypes.number },
    { label: 'Calculated net', value: 'calc_net', dataType: FilterDataTypes.number },
    { label: 'Difference', value: 'diff', dataType: FilterDataTypes.number },
    { label: 'Currency', value: 'currency_code', dataType: FilterDataTypes.string }
]

const TExceptionsList: React.FunctionComponent<{ filters: IFilter[], totalCount: number }> = ({ filters, totalCount }) => {

    const { loading, error, data, fetchMore } = useQuery<IGetTransactionalException>(QUERY_TRANSACTIONAL_EXCEPTIONS, {
        variables: {
            limit: 100,
            offset: 0,
            filters: filters
        }
    })

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>

    const loadMore = () => {
        return fetchMore({
            variables: { offset: data?.transactional_exceptions.length },
            updateQuery: (prev, { fetchMoreResult }) => {
                return !fetchMoreResult ? prev : Object.assign({}, prev, {
                    transactional_exceptions: [...prev.transactional_exceptions, ...fetchMoreResult.transactional_exceptions]
                })
            }
        })
    }

    const exceptions = data?.transactional_exceptions || []

    return (
        <div className="grid">
            <div className="gridContents table" style={{ position: 'relative' }}>
                <TExceptionsTable exceptions={exceptions} />
                <div className="gridContents" style={{textAlign:'center', width: `100%`}}>
                    <p className="label" style={{marginBottom: 16, marginTop: 32, textAlign:'center', width: `100%`}}>
                        Showing <strong>{exceptions.length}</strong> Rows of <strong>{totalCount}</strong> Total Results
                    </p>
                    <Button onClick={loadMore}>Load More</Button>
                </div>
            </div>
        </div>
    )
}

// const createCounter = (counter: number) => {
//     return (
//         <div className="grid">
//             <span className="gridContents label" style={{marginBottom: 8}}>
//                 <strong>{counter}</strong> results
//             </span>
//         </div>
//     )
// }

const getCardFilters = (totalCounts: ICounter[],
    cardFilter: string,
    setCardFilter: (card: string) => void, 
    filterDraft: IFilter[], 
    filterApplied: IFilter[], 
    setFilterDraft: (f: IFilter[]) => void, 
    setFilterApplied: (f: IFilter[]) => void) => {

    const changeCardFilter = (cardFilter: string) => {
        const drafts = filterDraft.filter(f => !f.extraType)
        const applied = filterApplied.filter(f => !f.extraType)
        const typeFilter = {
            id: uuid(),
            field: 'source',
            command: FilterCommand.is,
            value: cardFilter,
            extraType: 'card'
        }
        if (cardFilter === 'all') {
            setFilterDraft(drafts)
            setFilterApplied(applied)
        } else {
            setFilterDraft([...drafts, typeFilter])
            setFilterApplied([...applied, typeFilter])
        }
        setCardFilter(cardFilter)
    }

    return (
        <div className="grid">
            <div className="containFilters gridContents">
                {cards.map(card => (
                    <Card key={card.type}
                        title={card.title}
                        count={getCountForName(totalCounts, card.type)}
                        active={cardFilter === card.type}
                        onClick={() => changeCardFilter(card.type)} />
                ))}
            </div>
        </div>
    )
}

export const TExceptionsView: React.FunctionComponent = () => {

    const [cardFilter, setCardFilter] = useState('all')
    const [filterDraft, setFilterDraft] = useState<IFilter[]>([createEmptyFilter()])
    const [filterApplied, setFilterApplied] = useState<IFilter[]>([])

    const { loading, error, data } = useQuery<IGetTransactionalCounts>(QUERY_TRANSACTIONAL_COUNTS, {
        variables: { names: cards.map(c => c.type) }
    });

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>

    const counters = data?.transactional_counts || []

    const onExportClick = () => downloadCSV('transactional_exceptions.csv', { offset: 0, limit: 1000000, filters: filterApplied })

    return (
        <main>
            <section>
                <ScreenTitle
                    title="Transactional Exceptions"
                    onExportClick={onExportClick}
                    icon={<DashboardIcon />}
                    arrowNaviagteTo="/" />

                {getCardFilters(counters, cardFilter, setCardFilter, filterDraft, filterApplied, setFilterDraft, setFilterApplied)}
                {/* {createCounter(getCountForName(counters, cardFilter))} */}
                <Filters fields={fields} 
                    filterDraft={filterDraft} filterApplied={filterApplied}
                    setFilterDraft={setFilterDraft} setFilterApplied={setFilterApplied} />
                <TExceptionsList filters={filterApplied} totalCount={getCountForName(counters, cardFilter)} />
            </section>
        </main>
    )
}
