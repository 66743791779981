import React from 'react'
import { ITransactionalException } from '../api/transactional-exceptions'
import { Table, Column, defaultTableRowRenderer} from 'react-virtualized'

interface TExceptionsTableProps {
    exceptions: ITransactionalException[]
}

const borderWidth = 2

const getTypeText = (exception: ITransactionalException) => {
    switch (exception.source) {
        case 'cmt': return <p>CMT</p>
        case 'dividend': return <p>Dividend</p>
        case 'term-deposit': return <p>Term Deposit</p>
        case 'manual': return <p>Manual</p>
        case 'adjustment': return <p>Adjustment</p>
        default: return ''
    }
}

const getCPCodeText = (exception: ITransactionalException) => {
    return <p>{exception.cp_code}</p>
}

const getYearText = (exception: ITransactionalException) => {
    return <p className="isAlignRight">{exception.financial_year}</p>
}

const getMonthText = (exception: ITransactionalException) => {
    return <p className="isAlignRight">{exception.financial_month}</p>
}

const getWithholdCodeText = (exception: ITransactionalException) => {
    return <p className="isAlignCenter">{exception.withhold_code}</p>
}

const getGrossText = (exception: ITransactionalException) => {
    return <p className="isAlignRight">{exception.gross}</p>
}

const getRateText = (exception: ITransactionalException) => {
    return <p className="isAlignRight">{exception.rate}</p>
}

const getNetText = (exception: ITransactionalException) => {
    return <p className="isAlignRight">{exception.net}</p>
}

const getCalculatedNetText = (exception: ITransactionalException) => {
    return <p className="isAlignRight">{exception.calc_net}</p>
}

const getDifferenceText = (exception: ITransactionalException) => {
    return <p className="isAlignRight">{exception.diff}</p>
}

const getCurrencyText = (exception: ITransactionalException) => {
    return <p className="isAlignCenter">{exception.currency_code}</p>
}

export const TExceptionsTable: React.FunctionComponent<TExceptionsTableProps> = ({ exceptions }) => {

    // get the Windows Dimension
    const [winWidth, setWidth] = React.useState(window.innerWidth)
    const updateWidth = () => {
        setWidth(window.innerWidth)
    }
    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    })
    // Table width is windows width minus Sidebar, Padding Left and Right,
    const tableWidth = winWidth - 256 - 160

    return (
        <div className='StaticExceptionTable'>
            <Table
                width={tableWidth}
                height={840}
                headerHeight={44}
                rowHeight={44}
                rowClassName={'table--row'}
                headerClassName=" isHeader table--cell"
                rowCount={exceptions.length}
                rowGetter={({ index }) => exceptions[index]}
                rowRenderer={defaultTableRowRenderer}
                >
                <Column
                    label="Type"
                    dataKey="source"
                    className="table--cell"
                    cellRenderer={args => getTypeText(args.rowData)}
                    width={tableWidth * .2 - borderWidth}
                />
                <Column
                    label="cp code"
                    dataKey="cp_code"
                    className="table--cell"
                    cellRenderer={args => getCPCodeText(args.rowData)}
                    width={tableWidth * .2}
                />
                <Column
                    label="Fin. year"
                    dataKey="financial_year"
                    className="table--cell"
                    cellRenderer={args => getYearText(args.rowData)}
                    width={tableWidth * .1}
                />
                <Column
                    label="Fin. month"
                    dataKey="financial_month"
                    className="table--cell"
                    cellRenderer={args => getMonthText(args.rowData)}
                    width={tableWidth * .1}
                />
                <Column
                    label="withhold code"
                    dataKey="withhold_code"
                    className="table--cell"
                    cellRenderer={args => getWithholdCodeText(args.rowData)}
                    width={tableWidth * .3}
                />
                <Column
                    label="tax rate"
                    dataKey="rate"
                    className="table--cell"
                    cellRenderer={args => getRateText(args.rowData)}
                    width={tableWidth * .15}
                />
                <Column
                    label="gross"
                    dataKey="gross"
                    className="table--cell"
                    cellRenderer={args => getGrossText(args.rowData)}
                    width={tableWidth * .15}
                />
                <Column
                    label="net"
                    dataKey="net"
                    className="table--cell"
                    cellRenderer={args => getNetText(args.rowData)}
                    width={tableWidth * .15}
                />
                <Column
                    label="calculated"
                    dataKey="calc_net"
                    className="table--cell"
                    cellRenderer={args => getCalculatedNetText(args.rowData)}
                    width={tableWidth * .15}
                />
                <Column
                    label="difference"
                    dataKey="diff"
                    className="table--cell"
                    cellRenderer={args => getDifferenceText(args.rowData)}
                    width={tableWidth * .15}
                />
                <Column
                    label="currency"
                    dataKey="currency_code"
                    className="table--cell"
                    cellRenderer={args => getCurrencyText(args.rowData)}
                    width={tableWidth * .15}
                />
            </Table>


        </div>
    )
}