import React, { ReactNode, useState } from 'react'
import { v1 as uuid } from 'uuid'
import dayjs from 'dayjs'
import { useQuery } from '@apollo/react-hooks'
import { TableChartOutlined } from '@material-ui/icons'
import { SelectSingle, Button } from '@jarden-digital/component-lib'
import { Card, ScreenTitle, Filters, Table, createEmptyFilter, IFilterSelectEntry } from '../components'
import { IGetTransaction, QUERY_TRANSACTIONS, transactionTableDataMaker, transactionFilterFields, ITransaction, IAdjustment, adjustmentTableDataMaker } from '../api/transactions'
import { cmtTransactionTableDataMaker, cmtTransactionFilterFields, IGetCmtTransaction, QUERY_CMT } from '../api/cmt-transactions'
import { IGetTermDeposits, QUERY_TERM_DEPOSITS, termDepositsTableDataMaker, termDepositsFilterFields } from '../api/term-deposits'
import { dividendsTableDataMaker, IGetDividends, QUERY_DIVIDENDS, dividendsFilterFields } from '../api/dividends'
import { clientTableDataMaker, clientFilterFields, IGetClients, QUERY_CLIENTS } from '../api/clients'
import { FilterCommand, IFilter } from '../utils/filter'
import { downloadCSV, ICounter, getCountForName } from '../utils/common'
import { QUERY_TOTAL_COUNTS, IGetTotalCounts } from '../api/total-counts'

interface ISelectViewProps {
    view: string
    setView: (view: string) => void
}

interface ISetPeriod {
    period: string
    setPeriod: (period: string) => void
}

interface IViewProps {
    filters: IFilter[]
    createFilterComponent: (fields: IFilterSelectEntry[]) => ReactNode
    totalCount: number
}

const SelectView: React.FunctionComponent<ISelectViewProps> = ({ view, setView }) => {

    const commands = [
        { label: 'All Transactions', value: 'transactions' },
        { label: 'All CMT', value: 'cmt' },
        { label: 'Term Deposits', value: 'tds' },
        { label: 'Dividends', value: 'dividends' },
        { label: 'Clients', value: 'clients' }
    ]

    return (
        <div style={{ width: 180, marginRight: 32 }}>
            <SelectSingle
                placeholder="All Transactions"
                label="View"
                options={commands}
                value={view}
                onChange={v => setView(v)}
            />
        </div>
    )
}

const ReportingPeriod: React.FunctionComponent<ISetPeriod> = ({ period, setPeriod }) => {
    const commands = [
        { label: 'Not Selected', value: '' },
        { label: 'This Reporting Month', value: 'this-reporting-month' },
        { label: 'Year to Reporting Date', value: 'year-to-reporting-date' },
        { label: 'Post Reporting Period', value: 'post-reporting-period' },
    ]

    return (
        <div style={{ width: 220, marginRight: 32 }}>
            <SelectSingle
                placeholder="Not Selected"
                label="Reporting Period"
                options={commands}
                value={period}
                onChange={v => setPeriod(v)}
            />
        </div>
    )
}

// const createCounter = (counter: number) => {
//     return (
//         <div className="grid">
//             <span className="gridContents label" style={{marginBottom: 8}}>
//                 <strong>{counter}</strong> results
//             </span>
//         </div>
//     )
// }

const createAdjustmentTableData = (transactions: ITransaction[]) => {
    let odd = false
    return adjustmentTableDataMaker(transactions
        .sort((t1, t2) => new Date(t2.created).getTime() - new Date(t1.created).getTime())
        .reduce((memo: IAdjustment[], transaction: ITransaction) => {
            const notInMemo = ((t: ITransaction) => !memo.find(a => a.transaction.transaction_id === t.transaction_id))
            if (notInMemo(transaction)) {
                odd = !odd
                const original = transactions.find(t => t.transaction_id === transaction.adjustment_reference)
                const group = [
                    ...memo,
                    { transaction: transaction, isOdd: odd },
                    ...transactions.filter(t => !!transaction.adjustment_reference &&
                        t.transaction_id !== transaction.transaction_id &&
                        t.adjustment_reference === transaction.adjustment_reference && notInMemo(t))
                        .map(t => ({ transaction: t, isOdd: odd }))
                ]
                if (!!original && notInMemo(original)) {
                    return [...group, { transaction: original, isOdd: odd }]
                } else {
                    return group
                }
            } else {
                return memo
            }
        }, [])
    )
}


const AllTransactionsView: React.FunctionComponent<IViewProps> = ({ filters, createFilterComponent, totalCount }) => {

    const { loading, error, data, fetchMore } = useQuery<IGetTransaction>(QUERY_TRANSACTIONS, {
        variables: {
            limit: 1000,
            offset: 0,
            filters: filters
        }
    })

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>

    const loadMore = () => {
        return fetchMore({
            variables: { offset: data?.transactions.length },
            updateQuery: (prev, { fetchMoreResult }) => {
                return !fetchMoreResult ? prev : Object.assign({}, prev, {
                    transactions: [...prev.transactions, ...fetchMoreResult.transactions]
                })
            }
        })
    }

    const headers = transactionFilterFields()

    const isAdjustmentView = !!filters.find(f => f.field === 'transaction_type' && f.command === 'is' && f.value === 'adjustment')
    const transactions = data?.transactions || []
    const count = isAdjustmentView ? transactions.filter(t => t.transaction_type === 'Adjustment').length : transactions.length
    const tableData = isAdjustmentView ? createAdjustmentTableData(transactions) : transactionTableDataMaker(transactions)

    return (
        <div>
            {/* {createCounter(totalCount)} */}
            {createFilterComponent(headers)}
            <div className="grid">
                <div className="gridContents table" style={{ position: 'relative' }}>
                    <div className="gridContents table isLarge" style={{ paddingRight: 80 }}>
                        <Table headers={headers} tableData={tableData} />
                    </div>
                    <div className="gridContents" style={{ textAlign: 'center', width: `100%` }}>
                        <p className="label" style={{ marginBottom: 16, marginTop: 32, textAlign: 'center', width: `100%` }}>
                            Showing <strong>{count}</strong> Rows of <strong>{totalCount}</strong> Total Results
                        </p>
                        <Button onClick={loadMore}>Load More</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CmtTransactionsView: React.FunctionComponent<IViewProps> = ({ filters, createFilterComponent, totalCount }) => {

    const { loading, error, data, fetchMore } = useQuery<IGetCmtTransaction>(QUERY_CMT, {
        variables: {
            ignoreZeros: true,
            limit: 1000,
            offset: 0,
            filters: filters
        }
    })

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>

    const loadMore = () => {
        return fetchMore({
            variables: { offset: data?.cmt_transactions.length },
            updateQuery: (prev, { fetchMoreResult }) => {
                return !fetchMoreResult ? prev : Object.assign({}, prev, {
                    cmt_transactions: [...prev.cmt_transactions, ...fetchMoreResult.cmt_transactions]
                })
            }
        })
    }

    const headers = cmtTransactionFilterFields()
    const tableData = cmtTransactionTableDataMaker(data?.cmt_transactions || [])

    return (
        <div>
            {/* {createCounter(totalCount)} */}
            {createFilterComponent(headers)}
            <div className="grid">
                <div className="gridContents table" style={{ position: 'relative' }}>
                    <div className="gridContents table isLarge" style={{ paddingRight: 80 }}>
                        <Table headers={headers} tableData={tableData} />
                    </div>
                    <div className="gridContents" style={{ textAlign: 'center', width: `100%` }}>
                        <p className="label" style={{ marginBottom: 16, marginTop: 32, textAlign: 'center', width: `100%` }}>
                            Showing <strong>{tableData.length}</strong> Rows of <strong>{totalCount}</strong> Total Results
                        </p>
                        <Button onClick={loadMore}>Load More</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ClientsView: React.FunctionComponent<IViewProps> = ({ filters, createFilterComponent, totalCount }) => {

    const { loading, error, data, fetchMore } = useQuery<IGetClients>(QUERY_CLIENTS, {
        variables: {
            limit: 1000,
            offset: 0,
            filters: filters
        }
    })

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>

    const loadMore = () => {
        return fetchMore({
            variables: { offset: data?.clients.length },
            updateQuery: (prev, { fetchMoreResult }) => {
                return !fetchMoreResult ? prev : Object.assign({}, prev, {
                    clients: [...prev.clients, ...fetchMoreResult.clients]
                })
            }
        })
    }

    const headers = clientFilterFields()
    const tableData = clientTableDataMaker(data?.clients || [])

    return (
        <div>
            {/* {createCounter(totalCount)} */}
            {createFilterComponent(headers)}
            <div className="grid">
                <div className="gridContents table" style={{ position: 'relative' }}>
                    <div className="gridContents table isLarge" style={{ paddingRight: 80 }}>
                        <Table headers={headers} tableData={tableData} />
                    </div>
                    <div className="gridContents" style={{ textAlign: 'center', width: `100%` }}>
                        <p className="label" style={{ marginBottom: 16, marginTop: 32, textAlign: 'center', width: `100%` }}>
                            Showing <strong>{tableData.length}</strong> Rows of <strong>{totalCount}</strong> Total Results
                        </p>
                        <Button onClick={loadMore}>Load More</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const TermDepositsView: React.FunctionComponent<IViewProps> = ({ filters, createFilterComponent, totalCount }) => {

    const { loading, error, data, fetchMore } = useQuery<IGetTermDeposits>(QUERY_TERM_DEPOSITS, {
        variables: {
            limit: 1000,
            offset: 0,
            filters: filters
        }
    })

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>

    const loadMore = () => {
        return fetchMore({
            variables: { offset: data?.term_deposits.length },
            updateQuery: (prev, { fetchMoreResult }) => {
                return !fetchMoreResult ? prev : Object.assign({}, prev, {
                    term_deposits: [...prev.term_deposits, ...fetchMoreResult.term_deposits]
                })
            }
        })
    }

    const headers = termDepositsFilterFields()
    const tableData = termDepositsTableDataMaker(data?.term_deposits || [])

    return (
        <div>
            {/* {createCounter(totalCount)} */}
            {createFilterComponent(headers)}
            <div className="grid">
                <div className="gridContents table" style={{ position: 'relative' }}>
                    <div className="gridContents table isLarge" style={{ paddingRight: 80 }}>
                        <Table headers={headers} tableData={tableData} />
                    </div>
                    <div className="gridContents" style={{ textAlign: 'center', width: `100%` }}>
                        <p className="label" style={{ marginBottom: 16, marginTop: 32, textAlign: 'center', width: `100%` }}>
                            Showing <strong>{tableData.length}</strong> Rows of <strong>{totalCount}</strong> Total Results
                        </p>
                        <Button onClick={loadMore}>Load More</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const DividendsView: React.FunctionComponent<IViewProps> = ({ filters, createFilterComponent, totalCount }) => {

    const { loading, error, data, fetchMore } = useQuery<IGetDividends>(QUERY_DIVIDENDS, {
        variables: {
            limit: 1000,
            offset: 0,
            filters: filters
        }
    })

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>

    const loadMore = () => {
        return fetchMore({
            variables: { offset: data?.dividends.length },
            updateQuery: (prev, { fetchMoreResult }) => {
                return !fetchMoreResult ? prev : Object.assign({}, prev, {
                    dividends: [...prev.dividends, ...fetchMoreResult.dividends]
                })
            }
        })
    }

    const headers = dividendsFilterFields()
    const tableData = dividendsTableDataMaker(data?.dividends || [])

    return (
        <div>
            {/* {createCounter(totalCount)} */}
            {createFilterComponent(headers)}
            <div className="grid">
                <div className="gridContents table" style={{ position: 'relative' }}>
                    <div className="gridContents table isLarge" style={{ paddingRight: 80 }}>
                        <Table headers={headers} tableData={tableData} />
                    </div>
                    <div className="gridContents" style={{ textAlign: 'center', width: `100%` }}>
                        <p className="label" style={{ marginBottom: 16, marginTop: 32, textAlign: 'center', width: `100%` }}>
                            Showing <strong>{tableData.length}</strong> Rows of <strong>{totalCount}</strong> Total Results
                        </p>
                        <Button onClick={loadMore}>Load More</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const getView = (view: string,
    cardFilter: string,
    totalCounts: ICounter[],
    filterDraft: IFilter[],
    filterApplied: IFilter[],
    setFilterDraft: React.Dispatch<React.SetStateAction<IFilter[]>>,
    setFilterApplied: React.Dispatch<React.SetStateAction<IFilter[]>>,
) => {
    const createFilterComponent: (fields: IFilterSelectEntry[]) => ReactNode = (fields: IFilterSelectEntry[]) =>
        <Filters fields={fields} filterDraft={filterDraft} filterApplied={filterApplied} setFilterDraft={setFilterDraft}
            setFilterApplied={setFilterApplied} />

    switch (view) {
        case 'transactions':
            return <AllTransactionsView
                filters={filterApplied}
                createFilterComponent={createFilterComponent}
                totalCount={getCountForName(totalCounts, 'transactions-' + cardFilter)} />
        case 'cmt':
            return <CmtTransactionsView
                filters={filterApplied}
                createFilterComponent={createFilterComponent}
                totalCount={getCountForName(totalCounts, 'cmt')} />
        case 'tds':
            return <TermDepositsView
                filters={filterApplied}
                createFilterComponent={createFilterComponent}
                totalCount={getCountForName(totalCounts, 'term_deposits')} />
        case 'dividends':
            return <DividendsView
                filters={filterApplied}
                createFilterComponent={createFilterComponent}
                totalCount={getCountForName(totalCounts, 'dividends')} />
        case 'clients':
            return <ClientsView
                filters={filterApplied}
                createFilterComponent={createFilterComponent}
                totalCount={getCountForName(totalCounts, 'clients')} />
        default:
            return null
    }
}


const getCsvFilename = (view: string) => {
    switch (view) {
        case 'transactions':
            return 'transactions.csv'
        case 'cmt':
            return 'cmt.csv'
        case 'tds':
            return 'tds.csv'
        case 'dividends':
            return 'dividends.csv'
        case 'clients':
            return 'clients.csv'
        default:
            return ''
    }
}

const getReportingPeriod = (period: string,
    changePeriod: (p: string) => void,
    filterDraft: IFilter[],
    filterApplied: IFilter[],
    setFilterDraft: (f: IFilter[]) => void,
    setFilterApplied: (f: IFilter[]) => void
): JSX.Element => {
    const setPeriod = (period: string) => {
        const drafts = filterDraft.filter(f => f.extraType !== 'period')
        const applied = filterApplied.filter(f => f.extraType !== 'period')
        if (period === 'this-reporting-month') {
            const newFilters = [
                {
                    id: uuid(),
                    field: 'movement_date',
                    command: FilterCommand.dateAfter,
                    value: dayjs().subtract(1, 'month').startOf('month').format('DD/MM/YYYY'),
                    extraType: 'period'
                },
                {
                    id: uuid(),
                    field: 'movement_date',
                    command: FilterCommand.dateBefore,
                    value: dayjs().startOf('month').subtract(1, 'day').format('DD/MM/YYYY'),
                    extraType: 'period'
                }
            ]
            setFilterDraft([...drafts, ...newFilters])
            setFilterApplied([...applied, ...newFilters])
        } else if (period === 'year-to-reporting-date') {
            const newFilters = [
                {
                    id: uuid(),
                    field: 'movement_date',
                    command: FilterCommand.dateAfter,
                    value: dayjs().startOf('year').subtract(1, 'year').add(3, 'month').format('DD/MM/YYYY'),
                    extraType: 'period'
                },
                {
                    id: uuid(),
                    field: 'movement_date',
                    command: FilterCommand.dateBefore,
                    value: dayjs().startOf('month').subtract(1, 'day').format('DD/MM/YYYY'),
                    extraType: 'period'
                }
            ]
            setFilterDraft([...drafts, ...newFilters])
            setFilterApplied([...applied, ...newFilters])
        } else if (period === 'post-reporting-period') {
            const newFilters = [
                {
                    id: uuid(),
                    field: 'movement_date',
                    command: FilterCommand.dateAfter,
                    value: dayjs().startOf('month').format('DD/MM/YYYY'),
                    extraType: 'period'
                },
                {
                    id: uuid(),
                    field: 'movement_date',
                    command: FilterCommand.dateBefore,
                    value: dayjs().startOf('day').format('DD/MM/YYYY'),
                    extraType: 'period'
                }
            ]
            setFilterDraft([...drafts, ...newFilters])
            setFilterApplied([...applied, ...newFilters])
        } else if (period === '') {
            setFilterDraft(drafts)
            setFilterApplied(applied)
        }
        changePeriod(period)
    }

    return <ReportingPeriod period={period} setPeriod={setPeriod} />
}


const getCardFilters = (totalCounts: ICounter[],
    cardFilter: string,
    setCardFilter: (card: string) => void,
    filterDraft: IFilter[],
    filterApplied: IFilter[],
    setFilterDraft: (f: IFilter[]) => void,
    setFilterApplied: (f: IFilter[]) => void) => {

    const cards = [
        { type: 'all', title: 'All Transaction' },
        { type: 'cmt', title: 'CMT' },
        { type: 'dividend', title: 'Dividends' },
        { type: 'term deposit', title: 'Term Deposits' },
        { type: 'manual', title: 'Manuals' },
        { type: 'adjustment', title: 'Adjustments' }
    ]

    const changeCardFilter = (cardFilter: string) => {
        const drafts = filterDraft.filter(f => f.extraType !== 'card')
        const applied = filterApplied.filter(f => f.extraType !== 'card')
        const typeFilter = {
            id: uuid(),
            field: 'transaction_type',
            command: FilterCommand.is,
            value: cardFilter,
            extraType: 'card'
        }
        if (cardFilter === 'all') {
            setFilterDraft(drafts)
            setFilterApplied(applied)
        } else {
            setFilterDraft([...drafts, typeFilter])
            setFilterApplied([...applied, typeFilter])
        }
        setCardFilter(cardFilter)
    }

    return (
        <div className="grid">
            <div className="containFilters gridContents">
                {cards.map(card => (
                    <Card key={card.type}
                        title={card.title}
                        count={getCountForName(totalCounts, 'transactions-' + card.type)}
                        active={cardFilter === card.type}
                        onClick={() => changeCardFilter(card.type)} />
                ))}
            </div>
        </div>
    )
}

export const ViewBuilder: React.FunctionComponent = () => {
    const [filterDraft, setFilterDraft] = useState<IFilter[]>([createEmptyFilter()])
    const [filterApplied, setFilterApplied] = useState<IFilter[]>([])
    const [view, setView] = useState('transactions')
    const [period, setPeriod] = useState('')
    const [cardFilter, setCardFilter] = useState('all')

    const { loading, error, data } = useQuery<IGetTotalCounts>(QUERY_TOTAL_COUNTS)
    if (loading) return <p>Loading...</p>
    if (error) return <p>Error :(</p>
    const totalCounts = data?.total_counts || []

    const onExportClick = () => {
        downloadCSV(getCsvFilename(view), { ignoreZeros: true, offset: 0, limit: 1000000, filters: filterApplied })
    }

    const reportingPeriod = view === 'clients' ? undefined
        : getReportingPeriod(period, setPeriod, filterDraft, filterApplied, setFilterDraft, setFilterApplied)

    const cardFilters = view !== 'transactions' ? undefined
        : getCardFilters(totalCounts, cardFilter, setCardFilter, filterDraft, filterApplied, setFilterDraft, setFilterApplied)

    return (
        <main>
            <section>
                <ScreenTitle
                    title={'View Builder'}
                    icon={<TableChartOutlined />}
                    onExportClick={onExportClick}
                    reportingPeriod={reportingPeriod}
                    selectView={<SelectView view={view} setView={(view) => {
                        if (view === 'clients') {
                            setFilterDraft([createEmptyFilter()])
                            setFilterApplied([])
                            setPeriod('')
                        } else {
                            setFilterDraft(filterDraft.filter(f => f.extraType === 'period').concat([createEmptyFilter()]))
                            setFilterApplied(filterApplied.filter(f => f.extraType === 'period'))
                        }
                        setView(view)
                    }} />} />
                {cardFilters}
                {getView(view, cardFilter, totalCounts, filterDraft, filterApplied, setFilterDraft, setFilterApplied)}
            </section>
        </main>
    )
}
