import React from 'react'

interface CardProps {
    title?: string
    trendUp?: boolean
    active?: boolean
    count?: number | string
    slim?: boolean
    onClick?: () => void
}


export const Card: React.FunctionComponent<CardProps> = props => {
    const { title, count, onClick, active, slim } = props

    // const trend = trendUp ? <ArrowUpward /> : <ArrowDownward />
    const className = ['Card', active ? 'active' : '', slim ? 'slim' : ''].join(' ')
    
    return (
        <div className={ className } onClick={ onClick }>
            <div className="Card--header">
                <span>{ title }</span>
                {/* Trend indicate if the number of exception has increased or decreased since yesterday: It helps the user to manage their work load  { trend } */}
            </div>
            <div className="Card--content">
                { count }
            </div>
        </div>
    )
}