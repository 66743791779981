import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { AuthService } from './utils/auth0.service'
import { Dashboard, ClientsList, SExceptionsView, TExceptionsView, ViewBuilder } from './pages'
import { SideNav } from './components'


const App: React.FunctionComponent<{ authService: AuthService }> = ({ authService }) => {
    return (
        <HashRouter>
            <div className="app">
                <SideNav authService={authService} />
                <div className="sideNavPlaceholder "/>
                <div>
                    <Switch>
                        <Route exact path='/' component={Dashboard} />
                        <Route exact path='/static' component={SExceptionsView} />
                        <Route exact path='/transactional' component={TExceptionsView} />
                        <Route exact path='/builder' component={ViewBuilder} />
                        <Route exact path='/clients' component={ClientsList} />
                    </Switch>
                </div>
            </div>
        </HashRouter>
    )
}

export default App

