// import * as R from 'ramda'
// import dayjs from 'dayjs'
// import customParseFormat from 'dayjs/plugin/customParseFormat'
// dayjs.extend(customParseFormat)

export enum FilterDataTypes {
    'number' = 'number',
    'date' = 'date',
    'enum' = 'enum',
    'string' = 'string'
}

export enum FilterCommand {
    'null' = 'null',
    'is' = 'is',
    'isNot' = 'isNot',
    'equal' = 'equal',
    'notEqual' = 'notEqual',
    'contain' = 'contain',
    'notContain' = 'notContain',
    'empty' = 'empty',
    'notEmpty' = 'notEmpty',
    'isZero' = 'isZero',
    'notZero' = 'notZero',
    'gt' = 'gt',
    'lt' = 'lt',
    // 'fuzzySearch' = 'fuzzySearch',
    'dateMatch' = 'dateMatch',
    'dateBefore' = 'dateBefore',
    'dateAfter' = 'dateAfter',
}

export interface IFilter {
    id: string
    field: string
    command: FilterCommand
    value: string
    extraType?: string
}

interface IFilterType {
    dataType: FilterDataTypes,
    allowedCommands: FilterCommand[]
}

const filterTypes: IFilterType[] = [
    {
        dataType: FilterDataTypes.number,
        allowedCommands: [
            FilterCommand.equal,
            FilterCommand.notEqual,
            FilterCommand.isZero,
            FilterCommand.notZero,
            FilterCommand.gt,
            FilterCommand.lt,
        ]
    },
    {
        dataType: FilterDataTypes.date,
        allowedCommands: [
            FilterCommand.dateMatch,
            FilterCommand.dateAfter,
            FilterCommand.dateBefore,
            FilterCommand.empty,
            FilterCommand.notEmpty,
        ]
    },
    {
        dataType: FilterDataTypes.enum,
        allowedCommands: [
            FilterCommand.is,
            FilterCommand.isNot,
            FilterCommand.empty,
            FilterCommand.notEmpty,
            FilterCommand.contain,
            FilterCommand.notContain,
        ]
    },
    {
        dataType: FilterDataTypes.string,
        allowedCommands: [
            FilterCommand.is,
            FilterCommand.isNot,
            FilterCommand.empty,
            FilterCommand.notEmpty,
            FilterCommand.contain,
            FilterCommand.notContain,
            // FilterCommands.fuzzySearch
        ]
    },
]

interface FilterCreator<T = any> {
    (filters: IFilter[]): Filter<T>
}

export interface Filter<T = any> {
    (data: T[]): T[]
}

interface IFilterCommandFunc {
    (search: string): (value: string) => boolean
}

export interface IFilterCommand {
    value: FilterCommand,
    label: string,
    // func: IFilterCommandFunc
}

const filterCommands: IFilterCommand[] = [
    {
        value: FilterCommand.null,
        label: '',
        // func: (search: string) => (value: string) => true
    },
    {
        value: FilterCommand.contain,
        label: 'contain',
        // func: (search: string) => (value: string) => (value + '').toLowerCase().includes(search.toLowerCase())
    },
    {
        value: FilterCommand.notContain,
        label: 'does not contain',
        // func: (search: string) => (value: string) => !(value + '').toLowerCase().includes(search.toLowerCase()),
    },
    {
        value: FilterCommand.is,
        label: 'is',
        // func: (search: string) => (value: string) => (value + '').toLowerCase() === search.toLowerCase()
    },
    {
        value: FilterCommand.isNot,
        label: 'is not',
        // func: (search: string) => (value: string) => (value + '').toLowerCase() !== search.toLowerCase()
    },
    {
        value: FilterCommand.equal,
        label: 'equal to',
        // func: (search: string) => (value: string) => +value === +search
    },
    {
        value: FilterCommand.notEqual,
        label: 'not equal to',
        // func: (search: string) => (value: string) => +value !== +search
    },
    {
        value: FilterCommand.empty,
        label: 'is empty',
        // func: (search: string) => (value: string) => !value,
    },
    {
        value: FilterCommand.notEmpty,
        label: 'is not empty',
        // func: (search: string) => (value: string) => !!value,
    },
    {
        value: FilterCommand.isZero,
        label: 'is zero'
    },
    {
        value: FilterCommand.notZero,
        label: 'is not zero'
    },
    {
        value: FilterCommand.gt,
        label: 'is greater than',
        // func: (search: string) => (value: string) => +value > +search,
    },
    {
        value: FilterCommand.lt,
        label: 'is less than',
        // func: (search: string) => (value: string) => +value < +search
    },
    {
        value: FilterCommand.dateMatch,
        label: 'is',
        // func: (search: string) => (value: string) => {
        //     const valueDate = dayjs(value, "DD/MM/YY")
        //     const searchDate = dayjs(search.replace(/[^\d]/g, '/'), search.length < 10 ? "DD/MM/YY" : "DD/MM/YYYY" )
        //     return valueDate.isSame(searchDate)
        // }
    },
    {
        value: FilterCommand.dateBefore,
        label: 'to',
        // func: (search: string) => (value: string) => {
        //     const valueDate = dayjs(value, "DD/MM/YY")
        //     const searchDate = dayjs(search.replace(/[^\d]/g, '/'), search.length < 10 ? "DD/MM/YY" : "DD/MM/YYYY" ).endOf('day').add(1, 'minute')
        //     return valueDate.isBefore(searchDate)
        // }
    },
    {
        value: FilterCommand.dateAfter,
        label: 'from',
        // func: (search: string) => (value: string) => {
        //     const valueDate = dayjs(value, "DD/MM/YY")
        //     const searchDate = dayjs(search.replace(/[^\d]/g, '/'), search.length < 10 ? "DD/MM/YY" : "DD/MM/YYYY" ).startOf('day').subtract(1, 'minute')
        //     return valueDate.isAfter(searchDate)
        // }
    },
]

export const getFilterTypeByDataType = (filterDataType: FilterDataTypes): IFilterType => {
    const filterType = filterTypes.find(f => filterDataType === f.dataType)
    if (!filterType) throw new Error(`filterDataType ${filterDataType} not found`)
    return filterType
}

export const getFiltererCommandByCommandType = (commandType: FilterCommand): IFilterCommand => {
    const filterCommand = filterCommands.find(fm => fm.value === commandType)
    if (!filterCommand) throw new Error(`filterCommand ${filterCommand} not found`)
    return filterCommand
}

// const rowFilter = (filter: IFilter) => R.filter(
//     (row: any) => {
//         const filterCommandFunc = getFiltererCommandByCommandType(filter.command).func
//         const value: string | any = R.prop(filter.field)(row)
//         if (typeof value !== 'string') {
//             console.warn(`value ${value} is not string, please check`)
//         }
//         const search = filter.value;
//         return filterCommandFunc(search)(value)
//     }
// )

// const _filter = (filters: IFilter[], data: any[]) => {
//     //@ts-ignore
//     return R.reduce(((acc, filter) => rowFilter(filter)(acc)))(data)(filters)
// }

// export const filterCreator: FilterCreator = R.curry(_filter)

