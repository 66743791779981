
import * as React from 'react'
import { SubHeader } from './SubHeader'
import NumberFormat from 'react-number-format'
import { IPayableNumbers } from '../../api/dashboard'


interface NumberFormatCustomProps {
    int?: number
    float?: number
}

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
    const { int, float, ...other } = props
    const value = (!!float ? (float / 100.0) : int) || 0
    return (
        <NumberFormat
            {...other}
            allowNegative={true}
            thousandSeparator
            decimalScale={2}
            value={value}
            displayType={'text'}
            renderText={value => <p>{value}</p>}
        />
    )
}

export const PayableToIRD: React.FunctionComponent<{ payables: IPayableNumbers[] }> = ({ payables }) => {
    const rwt = payables.find(p => p.type === 'rwt')
    const ips = payables.find(p => p.type === 'ips')
    const nrwt = payables.find(p => p.type === 'nrwt')
    const ail = payables.find(p => p.type === 'ail')
    return (
        <div className='PayableToIRD grid'>
            <div className="gridContents">

                <SubHeader title='IRD Summary' />
                <div className='table isSmall'>
                    <div className='table--col isHeader'>
                        <div className='table--cell isEmpty isHeader' />
                        <div className='table--cell isHeader'>Tax deducted</div>
                        <div className='table--cell isHeader'>Gross earnings</div>
                        <div className='table--cell isHeader'>Lines count</div>
                    </div>
                    <div className='table--col fixed-size'>
                        <div className='table--cell isHeader'><p>RWT</p></div>
                        <div className='table--cell isFirst'><NumberFormatCustom float={rwt?.net} /></div>
                        <div className='table--cell isFirst'><NumberFormatCustom float={rwt?.gross} /></div>
                        <div className='table--cell isFirst'><NumberFormatCustom int={rwt?.lines} /></div>
                    </div>
                    <div className='table--col fixed-size'>
                        <div className='table--cell isHeader'><p>IPS</p></div>
                        <div className='table--cell '><NumberFormatCustom float={ips?.net} /></div>
                        <div className='table--cell '><NumberFormatCustom float={ips?.gross} /></div>
                        <div className='table--cell '><NumberFormatCustom int={ips?.lines} /></div>
                    </div>
                    <div className='table--col fixed-size'>
                        <div className='table--cell isHeader'><p>NRWT</p></div>
                        <div className='table--cell '><NumberFormatCustom float={nrwt?.net}/></div>
                        <div className='table--cell '><NumberFormatCustom float={nrwt?.gross} /></div>
                        <div className='table--cell '><NumberFormatCustom int={nrwt?.lines} /></div>
                    </div>
                    <div className='table--col fixed-size'>
                        <div className='table--cell isHeader'><p>AIL</p></div>
                        <div className='table--cell isLast'><NumberFormatCustom float={ail?.net} /></div>
                        <div className='table--cell isLast'><NumberFormatCustom float={ail?.gross} /></div>
                        <div className='table--cell isLast'><NumberFormatCustom int={ail?.lines} /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}