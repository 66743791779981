import React from 'react'
import { IContact, IAccount } from '../api/static-exceptions'


interface ContactRowProps {
    tableWidth: number
    contact: IContact
    cmtAccount?: IAccount
}

const renderTaxRate = (withhold_code: string| undefined) => {
    if (!withhold_code) {
        return <p className="isAlert">Missing</p>
    } else if (withhold_code.startsWith('NZ_RES_')) {
        return <p>{withhold_code.replace('NZ_RES_', '') + '%'}</p>
    } else if (withhold_code.startsWith('NONRES_')) {
        return <p>{withhold_code.replace('NONRES_', '')}</p>
    } else if (withhold_code.startsWith('NON_RES_')) {
        return <p>{withhold_code.replace('NON_RES_', '')}</p>
    } else {
        return <p>{withhold_code}</p>
    }
}

const renderTaxNumber = (contact: IContact) => {
    if (!contact.tax_number) {
        return <p className="isAlert">Missing</p>
    } else {
        return <p>{contact.tax_number}</p>
    }
}

const renderAddress = (contact: IContact) => {
    if (!contact.address1) {
        return <p className="isAlert">Missing street</p>
    } else {
        const addr = [contact.address1, contact.address2, contact.address3].filter(x => !!x).join(', ')
        return <p>{addr}</p>
    }
}

const renderCity = (contact: IContact) => {
    if (!contact.city_district) {
        return <p className="isAlert">Missing city</p>
    } else {
        const city = [contact.city_district, contact.postal_code].filter(x => !!x).join(', ')
        return <p>{city}</p>
    }
}

const renderCountry = (contact: IContact) => {
    if (!contact.country) {
        return <p className="isAlert">Missing country</p>
    } else {
        return <p>{contact.country}</p>
    }
}

const renderEmailAddress = (contact: IContact) => {
    if (!contact.email_address) {
        return <p className="isAlert isTruncated" style={{ maxWidth: 220 }}>Missing</p>
    } else {
        return <p className='isTruncated' style={{ maxWidth: 220 }}>{contact.email_address}</p>
    }
}


export const ContactRow: React.FunctionComponent<ContactRowProps> = props => {

    const { contact, tableWidth, cmtAccount} = props

    return (
        <div className="table--row">
            <div className="table--cell" style={{ width: tableWidth * .2 }}>
                <p>0{contact.contact_id} - {contact.first_name} {contact.last_name}</p>
                <small>se_cp_contact</small>
            </div>
            <div className="table--cell" style={{ width: tableWidth * .1 }}>
                {renderTaxRate(cmtAccount?.withhold_code)}
                <small>se_cp_account</small>
            </div>
            <div className="table--cell" style={{ width: tableWidth * .15 }}>
                {renderTaxNumber(contact)}
                <small>se_cp_contact</small>
            </div>
            <div className="table--cell " style={{ width: tableWidth * .32 }}>
                <div>{renderAddress(contact)}</div>
                <div>{renderCity(contact)}</div>
                <div>{renderCountry(contact)}</div>
                <small>se_cp_contact</small>
            </div>
            <div className="table--cell" style={{ width: tableWidth * .2 }} >
                {renderEmailAddress(contact)}
                <small>se_cp_contact</small>
            </div>
        </div>
    )
}


