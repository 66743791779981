import { gql } from 'apollo-boost'
import { defaultFormatter, ITableDataShape, ITableDataShapeMeta, missingFormatter } from '../utils/formatter'
import { FilterDataTypes } from '../utils/filter'


export const QUERY_CLIENTS = gql`
    query getClients($limit: Int, $offset: Int, $filters: [Filter], $sorting: Sorting) {
        clients(limit: $limit, offset: $offset, filters: $filters, sorting: $sorting) {
            cp_code
            cp_name
            cp_class
            investor_type
            withhold_code
            tax_number
            first_name
            last_name
            email_address
            address1
            address2
            address3
            city_district
            postal_code
            country
            country_iso_code
            tax_number_2
            first_name_2
            last_name_2
            email_address_2
            address1_2
            address2_2
            address3_2
            city_district_2
            postal_code_2
            country_2
            country_iso_code_2
        }
    }
`

export interface IClient {
	cp_code: string
	cp_name?: string
	cp_class: string
	investor_type: string
	withhold_code?: string
	tax_number?: string
	first_name?: string
	last_name?: string
	email_address?: string
	address1?: string
	address2?: string
	address3?: string
	city_district?: string
	postal_code?: string
	country?: string
	country_iso_code?: string
	tax_number_2?: string
	first_name_2?: string
	last_name_2?: string
	email_address_2?: string
	address1_2?: string
	address2_2?: string
	address3_2?: string
	city_district_2?: string
	postal_code_2?: string
	country_2?: string
	country_iso_code_2?: string
}

export interface IGetClients {
    clients: IClient[]
}

const headerData: IClient = {
    cp_class: '',
    cp_code: '',
    investor_type: 'JOINT_INDIVIDUAL'
}

const convert = (clients: IClient[]): ITableDataShape[][] => clients.map(client => {
    const joint = client.investor_type === 'JOINT_INDIVIDUAL'
    const secondaryContactMeta: ITableDataShapeMeta[] = joint ? [ITableDataShapeMeta.isOrange] :
        [ITableDataShapeMeta.isOrange, ITableDataShapeMeta.isOdd]
    return [
        {
            label: 'CP Code',
            dataType: FilterDataTypes.string,
            content: client.cp_code,
            value: 'cp_code',
            width: 120,
            formatter: defaultFormatter
        },
        {
            label: 'Name',
            dataType: FilterDataTypes.string,
            content: client.cp_name,
            value: 'cp_name',
            width: 460,
            formatter: defaultFormatter
        },
        {
            label: 'Investor Type',
            dataType: FilterDataTypes.enum,
            content: client.investor_type,
            value: 'investor_type',
            width: 170,
            formatter: defaultFormatter
        },
        {
            label: 'WT code',
            dataType: FilterDataTypes.string,
            content: client.withhold_code,
            value: 'withhold_code',
            width: 170,
            formatter: defaultFormatter,
        },
        {
            label: 'Class',
            dataType: FilterDataTypes.string,
            content: client.cp_class,
            value: 'cp_class',
            width: 260,
            formatter: defaultFormatter,
        },
        {
            label: 'First name',
            dataType: FilterDataTypes.string,
            content: client.investor_type === 'TRUST' || client.investor_type === 'COMPANY' ? '' : client.first_name,
            value: 'first_name',
            width: 260,
            meta: [ITableDataShapeMeta.isGreen],
            formatter: defaultFormatter,
        },
        {
            label: 'Last name',
            dataType: FilterDataTypes.string,
            content: client.investor_type === 'TRUST' || client.investor_type === 'COMPANY' ? '' : client.last_name,
            value: 'last_name',
            width: 260,
            meta: [ITableDataShapeMeta.isGreen],
            formatter: defaultFormatter,
        },
        {
            label: 'TIN',
            dataType: FilterDataTypes.string,
            content: client.tax_number,
            value: 'tax_number',
            width: 170,
            meta: [ITableDataShapeMeta.isGreen],
            formatter: missingFormatter,
        },
        {
            label: 'Email',
            dataType: FilterDataTypes.string,
            content: client.email_address,
            value: 'email_address',
            width: 320,
            meta: [ITableDataShapeMeta.isGreen],
            formatter: defaultFormatter,
        },
        {
            label: 'Address 1',
            dataType: FilterDataTypes.string,
            content: client.address1,
            value: 'address1',
            width: 260,
            meta: [ITableDataShapeMeta.isGreen],
            formatter: defaultFormatter,
        },
        {
            label: 'Address 2',
            dataType: FilterDataTypes.string,
            content: client.address2,
            value: 'address2',
            width: 260,
            meta: [ITableDataShapeMeta.isGreen],
            formatter: defaultFormatter,
        },
        {
            label: 'Address 3',
            dataType: FilterDataTypes.string,
            content: client.address3,
            value: 'address3',
            width: 260,
            meta: [ITableDataShapeMeta.isGreen],
            formatter: defaultFormatter,
        },
        {
            label: 'District',
            dataType: FilterDataTypes.string,
            content: client.city_district,
            value: 'city_district',
            meta: [ITableDataShapeMeta.isGreen],
            formatter: defaultFormatter,
        },
        {
            label: 'Postal Code',
            dataType: FilterDataTypes.string,
            content: client.postal_code,
            value: 'postal_code',
            meta: [ITableDataShapeMeta.isGreen],
            formatter: defaultFormatter,
        },
        {
            label: 'Country',
            dataType: FilterDataTypes.string,
            content: client.country,
            value: 'country',
            meta: [ITableDataShapeMeta.isGreen],
            formatter: defaultFormatter,
        },
        {
            label: 'Country Code',
            dataType: FilterDataTypes.string,
            content: client.country_iso_code,
            value: 'country_iso_code',
            meta: [ITableDataShapeMeta.isGreen],
            formatter: defaultFormatter,
        },
        {
            label: '2nd First name',
            dataType: FilterDataTypes.string,
            content: joint ? client.first_name_2 : '',
            value: 'first_name_2',
            width: 260,
            meta: secondaryContactMeta,
            formatter: defaultFormatter,
        },
        {
            label: '2nd Last name',
            dataType: FilterDataTypes.string,
            content: joint ? client.last_name_2 : '',
            value: 'last_name_2',
            width: 260,
            meta: secondaryContactMeta,
            formatter: defaultFormatter,
        },
        {
            label: '2nd TIN',
            dataType: FilterDataTypes.string,
            content: joint ? client.tax_number_2 : '',
            value: 'tax_number_2',
            meta: secondaryContactMeta,
            formatter: joint ? missingFormatter : defaultFormatter,
        },
        {
            label: '2nd Email',
            dataType: FilterDataTypes.string,
            content: joint ? client.email_address_2 : '',
            value: 'email_address_2',
            width: 320,
            meta: secondaryContactMeta,
            formatter: defaultFormatter,
        },
        {
            label: '2nd Address 1',
            dataType: FilterDataTypes.string,
            content: joint ? client.address1_2 : '',
            value: 'address1_2',
            width: 260,
            meta: secondaryContactMeta,
            formatter: defaultFormatter,
        },
        {
            label: '2nd Address 2',
            dataType: FilterDataTypes.string,
            content: joint ? client.address2_2 : '',
            value: 'address2_2',
            width: 260,
            meta: secondaryContactMeta,
            formatter: defaultFormatter,
        },
        {
            label: '2nd Address 3',
            dataType: FilterDataTypes.string,
            content: joint ? client.address3_2 : '',
            value: 'address3_2',
            width: 260,
            meta: secondaryContactMeta,
            formatter: defaultFormatter,
        },
        {
            label: '2nd District',
            dataType: FilterDataTypes.string,
            content: joint ? client.city_district_2 : '',
            value: 'city_district_2',
            meta: secondaryContactMeta,
            formatter: defaultFormatter,
        },
        {
            label: '2nd Postal Code',
            dataType: FilterDataTypes.string,
            content: joint ? client.postal_code_2 : '',
            value: 'postal_code_2',
            meta: secondaryContactMeta,
            formatter: defaultFormatter,
        },
        {
            label: '2nd Country',
            dataType: FilterDataTypes.string,
            content: joint ? client.country_2 : '',
            value: 'country_2',
            meta: secondaryContactMeta,
            formatter: defaultFormatter,
        },
        {
            label: '2nd Country Code',
            dataType: FilterDataTypes.string,
            content: joint ? client.country_iso_code_2 : '',
            value: 'country_iso_code_2',
            meta: secondaryContactMeta,
            formatter: defaultFormatter,
        },
    ]
})

export const clientFilterFields = (): ITableDataShape[] => convert([headerData])[0]

export const clientTableDataMaker = (clients: IClient[]): ITableDataShape[][] => convert(clients)
