import React, { useState } from 'react'
import { IStaticException } from '../api/static-exceptions'
import { Table, Column, RowMouseEventHandlerParams, defaultTableRowRenderer} from 'react-virtualized'
import { ContactRow } from './ContactRow'
import { formatDate } from '../utils/common'

interface SExceptionsTableProps {
    exceptions: IStaticException[]
}

const borderWidth = 2

const getTypeText = (exception: IStaticException) => {
    switch (exception.exception) {
        case 'no-ird': return <p>Missing IRD</p>
        case 'invalid-ird': return <p>Invalid IRD</p>
        case 'no-2nd-ird': return <p>Missing 2nd IRD</p>
        case 'invalid-2nd-ird': return <p>Invalid 2nd IRD</p>
        case 'equal-irds': return <p>Equal IRD numbers</p>
        case 'invalid-address': return <p>Invalid address</p>
        case 'no-country-code': return <p>Missing Country</p>
        case 'invalid-country-code': return <p>Unknown Country</p>
        case 'no-email-address': return <p>No E-mail</p>
        case 'invalid-email-address': return <p>Invalid E-mail</p>
        case 'no-tax-rate': return <p>Missing Tax Rate</p>
        case 'no-2nd-name': return <p>Missing 2nd person's name</p>
        default: return ''
    }
}

const getDescriptionText = (exception: IStaticException) => {
    const first_contact = exception.contacts[0] || {}
    const fullname =  first_contact.first_name ? <b>{first_contact.first_name} {first_contact.last_name}</b> : <span className="isALert">01 - Missing name</span>
    switch (exception.exception) {
        case 'no-ird': return <p>{fullname} IRD number is missing from account</p>
        case 'invalid-ird': return <p>{fullname} IRD number is not in valid format</p>
        case 'no-2nd-ird': return <p><b>Second</b> IRD number is missing from account</p>
        case 'invalid-2nd-ird': return <p><b>Second</b> IRD number is not in valid format</p>
        case 'equal-irds': return <p>The same IRD number for both persons</p>
        case 'invalid-address': return <p>{fullname} address is not valid</p>
        case 'no-country-code': return <p>{fullname} country code is missing from account</p>
        case 'invalid-country-code': return <p>{fullname} country code is not valid</p>
        case 'no-email-address': return <p>{fullname} e-mail address is missing</p>
        case 'invalid-email-address': return <p>{fullname} e-mail address is not valid</p>
        case 'no-tax-rate': return <p>{fullname} tax rate is missing from account</p>
        case 'no-2nd-name': return <p><b>Second person's name</b> is missing from account</p>
        default: return ''
    }
}

const getDateText = (exception: IStaticException) => {
    return <p>{formatDate(exception.created)}</p>
}

const getCPCodeText = (exception: IStaticException) => {
    return <p>{exception.cp_code}</p>
}


const getContactRows = (tableWidth: number, topPosition: number, exception: IStaticException | null) => {
    if (exception === null) return null

    const cmtAccount = exception.accounts.find(acc => acc.account_id === 'CMT')
    return (
        <div className="table--row--showMore table" style={{ zIndex: 1000, top: topPosition, width: tableWidth - borderWidth * 2 }}>
            {
                exception.contacts.length > 2 
                ?  <p className='label isAlert' style={{padding:16}}>{exception.contacts.length} Persons on Account</p> 
                : null  
            }
            <div className="table--row isHeader">
                <div className="table--cell isHeader" style={{ width: tableWidth * .2 }}><p>Full Name</p></div>
                <div className="table--cell isHeader" style={{ width: tableWidth * .1 }}><p>Tax Rate</p></div>
                <div className="table--cell isHeader" style={{ width: tableWidth * .15 }}><p>TIN</p></div>
                <div className="table--cell isHeader" style={{ width: tableWidth * .32 }}><p>Address</p></div>
                <div className="table--cell isHeader" style={{ width: tableWidth * .2 }}><p>Email</p></div>
            </div>
            {exception.contacts.map(contact => (
                <ContactRow
                    key={contact.contact_id}
                    tableWidth={tableWidth}
                    contact={contact}
                    cmtAccount={cmtAccount}
                />
            ))}
        </div>
    )
}

const rowRenderer = (tableWidth: number, extendedException: IStaticException | null, selectedIndex: number) => (props: any) => { // todo fixme any type
    const { index } = props;
    const topPosition = selectedIndex * 44 + 40;

    if (index === selectedIndex) {
        return <div>
            {defaultTableRowRenderer(props)}
            {getContactRows(tableWidth, topPosition, extendedException)}
        </div>
    }
    return defaultTableRowRenderer(props)
}

export const SExceptionsTable: React.FunctionComponent<SExceptionsTableProps> = ({ exceptions }) => {

    const [extendedException, setExtendedException] = useState<IStaticException | null>(null)
    const [selectedIndex, setSelectedIndex] = useState<number>(-1)

    // get the Windows Dimension
    const [winWidth, setWidth] = React.useState(window.innerWidth)
    const updateWidth = () => {
        setWidth(window.innerWidth)
    }
    React.useEffect(() => {
        window.addEventListener("resize", updateWidth);
        return () => window.removeEventListener("resize", updateWidth);
    })
    // Table width is windows width minus Sidebar, Padding Left and Right,
    const tableWidth = winWidth - 256 - 160


    const onRowClick = (info: RowMouseEventHandlerParams) => {
        const rowException = info.rowData
        setSelectedIndex(info.index)
        setExtendedException(rowException.cp_code === extendedException?.cp_code ? null : rowException)
    }


    return (
        <div className='StaticExceptionTable'>
            <Table
                width={tableWidth}
                onRowClick={onRowClick}
                height={840}
                headerHeight={44}
                rowHeight={44}
                rowClassName={'table--row'}
                headerClassName=" isHeader table--cell"
                rowCount={exceptions.length}
                rowGetter={({ index }) => exceptions[index]}
                rowRenderer={rowRenderer(tableWidth, extendedException, selectedIndex)}
                >
                <Column
                    label="Type"
                    dataKey="exception"
                    className="table--cell"
                    cellRenderer={args => getTypeText(args.rowData)}
                    width={tableWidth * .15 - borderWidth}
                />
                <Column
                    label="cp code"
                    dataKey="cp_code"
                    className="table--cell"
                    cellRenderer={args => getCPCodeText(args.rowData)}
                    width={tableWidth * .15}
                />
                <Column
                    label="date"
                    dataKey="created"
                    className="table--cell"
                    cellRenderer={args => getDateText(args.rowData)}
                    width={tableWidth * .15}
                />
                <Column
                    label="description"
                    dataKey="exception"
                    className="table--cell"
                    cellRenderer={args => getDescriptionText(args.rowData)}
                    width={tableWidth * .55 - borderWidth}
                />
            </Table>


        </div>
    )
}