import React, { useState } from 'react'
import {
    ScreenTitle,
    Filters,
    IFilterSelectEntry,
    createEmptyFilter
} from '../components'
import { PeopleOutlined } from '@material-ui/icons'
import { ClientsSummaryTable } from '../contexts'
import { FilterDataTypes, IFilter } from '../utils/filter'

const fields: IFilterSelectEntry[] = [
    { label: 'CP Code', value: 'cp_code', dataType: FilterDataTypes.string },
    { label: 'Name', value: 'cp_name', dataType: FilterDataTypes.string },
    { label: 'Error type', value: 'type', dataType: FilterDataTypes.enum },
    { label: 'TIN', value: 'ird', dataType: FilterDataTypes.string },
    { label: 'Address', value: 'address', dataType: FilterDataTypes.string },
    { label: 'Country', value: 'country', dataType: FilterDataTypes.enum },
    { label: 'Email', value: 'email_address', dataType: FilterDataTypes.string },
    { label: 'Tax Rate', value: 'rate', dataType: FilterDataTypes.number }
]

export const ClientsList: React.FunctionComponent = () => {

    const [filterDraft, setFilterDraft] = useState<IFilter[]>([createEmptyFilter()])
    const [filterApplied, setFilterApplied] = useState<IFilter[]>([])

    return (
        <main>
            <section>
                <ScreenTitle
                    title="Client Summary"
                    icon={<PeopleOutlined />} />

                <Filters fields={fields} filterDraft={filterDraft} filterApplied={filterApplied} setFilterDraft={setFilterDraft} setFilterApplied={setFilterApplied} />
                <div className="grid">
                    <div className="gridContents table" style={{ position: 'relative' }}>
                        <ClientsSummaryTable filters={filterApplied} />
                    </div>
                </div>
            </section>
        </main>
    )
}
