import { gql } from 'apollo-boost'
import { ICounter } from '../utils/common'

export const QUERY_DASHBOARD = gql`
    query getDashboard {
        dashboard {
            counters {
                name
                count
            }
            reports {
                year
                month
                draft
            }
            audit_statics {
                year
                month
            }
            audit_adjustments {
                year
                month
            }
            payables {
                type
                gross
                net
                lines
            }
        }
    }
`

export interface IReportLink {
    year: number
    month: number
    draft: boolean
}

export interface IAuditPeriod {
	year: number
	month: number
}

export interface IPayableNumbers {
	type: string
	gross: number
	net: number
	lines: number
}

export interface IDashboardData {
    counters: ICounter[]
    reports: IReportLink[]
    audit_statics: IAuditPeriod[]
    audit_adjustments: IAuditPeriod[]
    payables: IPayableNumbers[]
}

export interface IGetDashboardData {
    dashboard: IDashboardData
}

